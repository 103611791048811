import { useMemo, useRef, useState } from "react";
import { UserRole } from "../types";

type UserRoleSelectProps = {
    availableRoles: UserRole[],
    currentUserRole: UserRole,
    updateSelectedRoles?: (selectedRole: UserRole) => void,
    className?: string,
    showRoleEditButton?: boolean,
    editRoleButtonClicked?: (userRoleEditing: UserRole) => void,
    id?: string,
    theme?: string
}

export const UserRoleSelect = ({availableRoles, currentUserRole, updateSelectedRoles: updateSelectedRole, className, showRoleEditButton = true, editRoleButtonClicked, id, theme="blue"} : UserRoleSelectProps) => {
    function onSelectedRolesUpdate(roleId: string) {
        const matchingRole = availableRoles.find((value) => value.userRoleId === roleId);

        if (matchingRole) {
            if (updateSelectedRole) updateSelectedRole(matchingRole);
        }
    }

    return (
        <div className={`flex flex-row ${className}`}>
            <select value={currentUserRole.userRoleId ?? ""} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSelectedRolesUpdate(e.target.value)} className="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" id={id}>
                <option value="" disabled>Select role...</option>
                {availableRoles.map((value) => 
                    <option value={value.userRoleId} key={value.userRoleId}>{value.name}</option>
                )}
            </select>
            {currentUserRole && currentUserRole.userRoleId &&
               <button className={`btn btn-secondary${theme === 'green' ? '-green' : ''} ml-2`} onClick={() => {if (editRoleButtonClicked) editRoleButtonClicked(currentUserRole)}}>Edit Role</button> 
            }
        </div>
    )
}