import React, { useState, useEffect } from 'react';
import { useScreen } from '../../context';
import { createBroadcast } from '../../api';
import { Broadcast } from '../../types';

import { getDateInD3Format, getTimeInD3Format } from '../../utils';

const NewBroadcastsPage = () => {
  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId("NEW-BROADCAST");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementMessage, setAnnouncementMessage] = useState("");
  const [announcementScope, setAnnouncementScope] = useState("All Sites");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const branches = ["Bournemouth Branch", "Hull Branch", "Sheffield Branch"];

  const attemptBroadcast = async () => {
    try {

      let dateToday = new Date();
      const broadcast = {
        TITLE: announcementTitle,
        DATE: getDateInD3Format(dateToday),
        TIME: getTimeInD3Format(dateToday),
        LOCATION: announcementScope,
        "MESSAGE.CONTENT": announcementMessage.split("\n"),
        "STAFF.CODE": "TEMP",
        "EXPIRY.DATE": getDateInD3Format(new Date(expiryDate)),
        "EXPIRY.TIME": expiryTime ? `${expiryTime}:00` : "",
      } as Broadcast;

      console.log(broadcast);

      const response = await createBroadcast(broadcast);
      console.log(response);
      addNotification("Announcement has been successfully broadcasted. Redirecting to the dashboard page...", "success");
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
    catch (error) {
      addNotification("There was a problem creating broadcast. Please try again.", "error");
      console.error('There was a problem creating broadcast:', error);
    }
  }

  return (
    <div className="flex flex-col gap-6 items-start w-full">
      <div className="flex w-full items-end gap-4 dashboard-card p-4">
        <h2 className="font-semibold text-2xl">New Announcement</h2>
        <button
          className="btn btn-secondary-green ml-auto"
          onClick={() => {
            window.location.href = "/config";
          }}
        >
          Discard
        </button>
        <button
          className="btn btn-green disabled:opacity-50"
          disabled={announcementTitle === "" || announcementMessage === "" || expiryDate === "" || expiryTime === ""}
          onClick={attemptBroadcast}
        >
          Broadcast
        </button>
      </div>

      <div className="grid md:grid-cols-3 gap-6 w-full">
        <div className="dashboard-card">
          <div className="card-header bg-green">
            <i className="fas fa-bullhorn"></i>
            <h3 className="font-semibold uppercase text-sm">Announcement Title</h3>
          </div>
          <div className="p-4">
            <input
              type="text"
              className="input text-xl text-light outline-none w-full font-bold"
              placeholder="Enter announcement title..."
              value={announcementTitle}
              onChange={(e) => setAnnouncementTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-green">
            <i className="fas fa-users"></i>
            <h3 className="font-semibold uppercase text-sm">Audience</h3>
          </div>
          <div className="p-4">
            <select 
              className="input text-xl text-light outline-none"
              value={announcementScope}
              onChange={(e) => setAnnouncementScope(e.target.value)}
            >
              <option value="All Sites">All Sites</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-green">
            <i className="fas fa-clock"></i>
            <h3 className="font-semibold uppercase text-sm">Expiry Date & Time</h3>
          </div>
          <div className="p-4 flex items-center justify-center gap-2">
            <input
              type="date"
              className="input text-light outline-none"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
            <input
              type="time"
              className="input outline-none"
              value={expiryTime}
              onChange={(e) => setExpiryTime(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="dashboard-card w-full">
        <div className="card-header bg-green">
          <i className="fas fa-comment-alt"></i>
          <h3 className="font-semibold uppercase text-sm">Announcement Message</h3>
        </div>
        <div className="p-4">
          <textarea
            className="input text-lg text-light outline-none w-full"
            placeholder="Enter announcement message..."
            rows={5}
            value={announcementMessage}
            onChange={(e) => setAnnouncementMessage(e.target.value)}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default NewBroadcastsPage;