import React, { useState, useEffect } from 'react';

type CustomerAndSupplierNotesProps = {
  note: string | string[] | undefined;
  setNote: (note: string[]) => void;
  theme: "blue" | "green";
};

const CustomerAndSupplierNotes: React.FC<CustomerAndSupplierNotesProps> = ({ note, setNote, theme }) => {
  const [noteContent, setNoteContent] = useState(note as string[] | string | undefined);
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    setNoteContent(note);
  }, [note]);

  const handleSave = () => {
    if (!noteContent || typeof noteContent === undefined) return;

    console.log(noteContent);
    setNote(noteContent as string[]);
    setEditState(false);
  };

  return (
    <div className="p-4">
      <textarea
        value={typeof noteContent === "string" 
          ? noteContent 
          : noteContent !== undefined
            ? noteContent.join('\n')
            : undefined}
        id="note-input"
        rows={8}
        className="w-full p-2 border border-gray-300 rounded focus:outline-none"
        onChange={(e) => setNoteContent(e.target.value.split('\n'))}
        placeholder="Enter your notes here..."
        disabled={!editState}
      />
      {!editState 
        ? <button
            className={`btn w-full mt-4 ${theme === "blue" ? "btn-primary" : "btn-green"}`}
            onClick={() => {
              setEditState(true)
              setTimeout(() => {
                let noteInput = document.getElementById('note-input') as HTMLTextAreaElement;
                noteInput.focus();
                noteInput.setSelectionRange(0, 0);
              }, 0);
            }}
          >
            Edit
          </button>
        : <>
            <button
              className={`btn w-full mt-4 ${theme === "blue" ? "btn-primary" : "btn-green"}`}
              onClick={handleSave}
            >
              Confirm
            </button>
            <button
              className="btn btn-outline w-full mt-4"
              onClick={() => {
                setNoteContent(note);
                setEditState(false);
              }}
            >
              Cancel
            </button>
          </>
      }
    </div>
  );
};

export default CustomerAndSupplierNotes;