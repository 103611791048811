import React, { useContext, useEffect, useRef, useState } from "react";
import VerticalTextInput from "../../components/verticalTextInput";
import { PronounSelect } from "../../components/pronounSelect";
import {User, UserLock, UserOrderLimits, UserPermissions, UserRole} from '../../types';
import { Navigate } from "react-router-dom";
import { UserRoleSelect } from "../../components/userRoleSelect";
import { businessAreas, propToHumanReadable, roles, users } from "../../dummyData";
import { UserPermissionCustomisation } from "../../components/userPermissionCustomisation";
import { Tooltip } from 'react-tooltip'
import { UIToggles } from "../../uitoggles";



type UserMaintenanceProps = {
    userId: string,
    closeCard: () => void
}

//TODO: Extract somewhere else so that this can be mocked.
function allUsers() {
    return users;
}

function getUserLocks(): UserLock[] {
    return [] as UserLock[]
}

function lockUser(lockingUserId: string, lockedByUserId: string, lockedForMilliseconds: number) {
    //Locking user needs to be enforced at server level too
    const lockedAt: Date = new Date();

    const lock: UserLock = {lockedUserId: lockingUserId, lockedByUserId: lockedByUserId, lockedAt: lockedAt, lockedForMilliseconds: lockedForMilliseconds}

    //TODO: Add to locks
}

export const UserMaintenance = ({userId, closeCard} : UserMaintenanceProps) => {
    //const currentUser = useContext();

    const [currentUserHasPermissionToEdit, setCurrentUserHasPermissionToEdit] = useState(false);
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false);
    const [editedUserIsLocked, setEditedUserIsLocked] = useState(false);
    const [userLockTimesOutInMillis, setUserLockTimesOut] = useState<number>(0)

    const [newUser, setNewUser] = useState<boolean>(!userId || userId === '');
    const [editedUserUID, setEditedUserUID] = useState<string>(userId);
    const [profileImage, setProfileImage] = useState<string>("tayah.png");
    const [username, setUsername] = useState<string>("");
    const [selectedRole, setSelectedRole] = useState<UserRole>({} as UserRole);
    const purchaseLimit = useRef<number>();
    const salesLimit = useRef<number>();
    const quoteLimit = useRef<number>();
    const invoiceLimit = useRef<number>();
    const [pronoun, setPronoun] = useState<string>('');
    const [userEmailAddress, setUserEmailAddress] = useState<string>('');
    const [userHasSuperPermissions, setUserHasSuperPermission] = useState<boolean>(false);
    const [permissionOverrides, setPermissionOverrides] = useState<UserPermissions>({} as UserPermissions);

    const [showOverrides, setShowOverrides] = useState(false);

    
    function superPermissionChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setUserHasSuperPermission(e.target.checked);
    }

    function selectPronoun(pronoun: string) {
        if (pronoun) setPronoun(pronoun);
    }

    function selectUserRole(selectedRole: UserRole) {
        setSelectedRole(selectedRole);
    }

    function editProfileImage() {
        let newProfileImage = profileImage;

        //Open menu/upload popup

        setProfileImage(newProfileImage);
    }

    function verifyOrderLimit(limit: number, limitName: string): string | undefined {
        //const limitNum = Number(limit);
        //if (isNaN(limitNum)) return `Limit ${limitName} was not a number!`;
        if (limit < 0) return `Limit ${limitName} is negative!`;
        if (limit % 1 !== 0) return `Limit ${limitName} is a fraction/decimal number!`;
        return undefined;
    }

    function areAllFieldsValid(): {success?: boolean, errorMessage?: string} {
        const errors: string[] = []

        if (!editedUserUID) errors.push("No User ID specified!");

        let duplicateUserId = users.map((user) => user.userId).includes(userId);
        if (newUser && duplicateUserId) errors.push(`User ID ${userId} already exists! Please choose a different user ID.`);

        //TODO: Prevent swears and other unappreciated language
        if (!username) errors.push("No username specified!");
        if (!userEmailAddress || !userEmailAddress.includes("@")) errors.push("No valid email specified!");
        //if (userEmailAddress && users.filter((value) => value.userId ))
        console.log(`Role includes ${selectedRole.userRoleId}, roles are: ${JSON.stringify(roles.map((value) => value.userRoleId))}`);
        if (!selectedRole || 
            !roles.map((value) => value.userRoleId).includes(selectedRole.userRoleId)) 
                errors.push(`Empty role or invalid role! Role ID is: ${selectedRole.userRoleId?.replace(' ', '#') ?? ""}!`);
        //Don't show error if pronouns aren't shown
        if (UIToggles.pronounsShown && !pronoun) errors.push("No pronouns specified!");
        
        const purchaseVerificationMsg = verifyOrderLimit(purchaseLimit.current ?? 0, "purchase");
        if (purchaseVerificationMsg) errors.push(`${purchaseVerificationMsg} Limit is ${purchaseLimit.current}!`);

        const salesVerificationMsg = verifyOrderLimit(salesLimit.current ?? 0, "sales");
        if (salesVerificationMsg) errors.push(`${salesVerificationMsg} Limit is ${salesLimit.current}!`);

        const quoteVerificationMsg = verifyOrderLimit(quoteLimit.current ?? 0, "quote");
        if (quoteVerificationMsg) errors.push(`${quoteVerificationMsg} Limit is ${quoteLimit.current}!`);

        const invoiceVerificationMsg = verifyOrderLimit(invoiceLimit.current ?? 0, "invoice");
        if (invoiceVerificationMsg) errors.push(`${invoiceVerificationMsg} Limit is ${invoiceLimit.current}!`);
        
        if (errors.length > 0) {
            let error = "";
            error += errors.map((value) => value).join("\n")
            console.log(error)
            return {success: false, errorMessage: error}
        }
        else return {success: true}
    }

    function saveUserChanges() {
        //Update user object
        const validDetailsMessage = areAllFieldsValid();
        if (validDetailsMessage.success) {
            let user: User | undefined = allUsers().find((value) => value.userId === userId);
            let creatingNewUser: boolean = false;

            if (!user || newUser) {
                console.log("Creating new user")
                creatingNewUser = true
                user = {} as User;
                user.userId = editedUserUID;
            }

            user.username = username;
            user.userEmailAddress = userEmailAddress;
            user.pronouns = pronoun;
            user.role = selectedRole;
            user.permissionOverrides = permissionOverrides;
            if (user.orderLimits) {
                user.orderLimits.invoice = invoiceLimit.current;
                user.orderLimits.purchase = purchaseLimit.current;
                user.orderLimits.quote = quoteLimit.current;
                user.orderLimits.sales = salesLimit.current;
            } else {
                user.orderLimits = {
                    invoice: invoiceLimit.current,
                    purchase: purchaseLimit.current,
                    quote: quoteLimit.current,
                    sales: salesLimit.current
                } as UserOrderLimits
            }
            user.isSuperUser = userHasSuperPermissions;

            if (creatingNewUser) {
                allUsers().push(user);
                console.log("Created user " + userId);
            } else {
                allUsers()[allUsers().indexOf(user)] = user;
                console.log("Updated user " + userId);
            }
            //Unlock user

            if (closeCard) closeCard()
        } else {
            alert("Cannot save. " + validDetailsMessage.errorMessage);
        }
    }

    function resetUserPassword() {
        
    }

    function sendWeeklyQuoteReport() {
        //Send to user email address
    }

    const LimitDefinition = (
            {
                limitLabel, 
                limitText, 
                defaultValue, 
                onChanged, 
                labelWidthClass
            } : {
                limitLabel: string, 
                limitText?: string, 
                defaultValue?: number, 
                onChanged?: (newValue: number) => void, 
                labelWidthClass?: string
            }) => {
        const [limit, setLimit] = useState<string>(defaultValue?.toString() ?? "");
        const id = "userMaintenanceLimitDefinition" + (limitLabel.split(' ').map((value) => value.at(0)?.toUpperCase() + value.slice(1)).join(''));

        function changeLimit(e: React.ChangeEvent<HTMLInputElement>) {
            setLimit(e.target.value);
            const newValue: number | undefined = Number(e.target.value);

            if (newValue && !isNaN(newValue)) {
                if (onChanged) {
                    onChanged(newValue);
                }
            }
        }

        return (
            <div className="w-full flex flex-col md:flex-row">
                <label className={`font-medium ${labelWidthClass ?? "w-20"}`.trim()} htmlFor={id}>{limitLabel}</label>
                <input 
                    className="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96"
                    id={id}
                    type="number" 
                    placeholder={limitText}
                    onChange={changeLimit}
                    value={limit}
                ></input>
            </div>
        )
    }

    useEffect(() => {
        //Query user admin status by sending a request via the API, don't store admin status in the user itself.
        //If not has permissions, set the current user has permission state to false
        //Current user will be communicated down by a context.
        //Note: Current user is not the user specified by userId.

        const user: User | undefined = allUsers().find((value) => value.userId === userId);

        if (user) {
            setNewUser(false);
            setUsername(user.username);
            setPronoun(user.pronouns ?? "");
            setUserEmailAddress(user.userEmailAddress ?? "");
            setSelectedRole(user.role);
            setProfileImage(user.profileImage);
            setUserHasSuperPermission(user.isSuperUser);
            purchaseLimit.current = user.orderLimits.purchase;
            invoiceLimit.current = user.orderLimits.invoice;
            quoteLimit.current = user.orderLimits.quote;
            salesLimit.current = user.orderLimits.sales;
            setPermissionOverrides(user.permissionOverrides ?? {} as UserPermissions);

            //Check for locks on editing the user
            const locked: boolean = getUserLocks().map((lock) => lock.lockedUserId).includes(user.userId);
            //setUserLockTimesOut(160000);

            setEditedUserIsLocked(locked);
        }

        setCurrentUserHasPermissionToEdit(true);
    }, [userId])

    if (!currentUserHasPermissionToEdit) return (
        <section className="dashboard-card p-4">
            <span>User has no permission to edit users.</span>
        </section>
        //<Navigate to={"/maintenance"}/>
    );

    if (editedUserIsLocked) return (
        <section className="dashboard-card p-4">
            <span>User {username} (User ID: {userId}) is locked for editing. This lock will expire in {Math.ceil(userLockTimesOutInMillis / 1000 / 60)} minutes, or when the locking user is done editing the user.</span>
        </section>
    )


    return (
        <section className="dashboard-card p-4">
            <div className="flex flex-col divide-y-2 gap-1">
                <header className="flex flex-row w-full ">
                    <div className="w-[95%]"><span className="font-bold text-2xl">User Maintenance</span></div>
                    <button onClick={() => {if (closeCard) closeCard()}}><i className="fa-solid fa-close text-red-600 border-2 rounded border-gray-300 pr-[6px] pl-[4px] text-xl hover:bg-gray-300"></i></button>
                </header>
                <div className="flex flex-col md:flex-row-reverse">
                    <div className="flex flex-col ml-4 content-center">
                        <label className="font-bold" htmlFor="userMaintenanceUserProfileImage">Profile Picture</label>
                        <img src={profileImage} id="userMaintenanceUserProfileImage" className="w-16 h-16 md:h-32 md:w-32 lg:h-48 lg:w-48 rounded-[50%]" alt="Large user profile"/>
                        <button className="btn btn-outline w-20" onClick={editProfileImage}><i className="fa-solid fa-pencil"></i>Edit</button>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <span className="font-bold">Personal Details</span>
                        <VerticalTextInput 
                            label={"User ID"}
                            value={editedUserUID}
                            labelColourClass="text-black" 
                            labelFontClass="text-lg font-bold"
                            inputClassName={"border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" + (newUser ? '' : ' bg-gray-100')}
                            className={"m-0"}
                            readonly={!newUser}
                            onValueChanged={(newUID: string) => {if (newUser) setEditedUserUID(newUID)}}
                        />
                        <span className="text-sm text-[#8181A5] mt-2">User ID must be unique among all users in the system.</span>
                        <VerticalTextInput 
                            label={"Name"} 
                            value={username} 
                            labelColourClass="text-black" 
                            labelFontClass="text-lg font-bold"
                            inputClassName="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" 
                            className="m-0" 
                            onValueChanged={(newUsername: string) => setUsername(newUsername)}
                        />
                        <span className="text-sm text-[#8181A5] mt-2">Your name will display within WareTech and be visible on any action completed. This is also the name that will appear on quotations and public facing documents.</span>
                        <VerticalTextInput 
                            label={"User Email Address"} 
                            value={userEmailAddress} 
                            labelColourClass="text-black" 
                            inputClassName="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" 
                            className="m-0" 
                            onValueChanged={(email: string) => setUserEmailAddress(email)}
                        />

                        {UIToggles.pronounsShown && 
                        <>
                            <label htmlFor="userMaintenancePronounSelect" className="font-bold text-sm mt-2">Pronouns</label>
                            <PronounSelect onPronounSelected={selectPronoun} className="w-full md:w-80 lg:w-96" defaultValue={pronoun} id="userMaintenancePronounSelect"/>
                        </>}

                        <label className="font-bold mt-4" htmlFor="userMaintenanceRoleSelect">User Defined Roles</label>
                        <div className="flex flex-col md:flex-row gap-2">
                            <UserRoleSelect 
                                currentUserRole={selectedRole} 
                                availableRoles={roles} 
                                className="w-full md:w-80 lg:w-96" 
                                updateSelectedRoles={selectUserRole}
                                id="userMaintenanceRoleSelect"
                                theme="green"
                            />
                        </div>
                        <span className="text-sm text-[#8181A5]">Select User Defined Role(s) to set permissions of this user. Limit of 1 role per Business area.</span>

                        <label className="font-bold mt-4" htmlFor="userMaintenancePermissionOverrides">User Permission Overrides: 
                            <abbr id="userMaintenancePermissionOverrides" title={showOverrides ? "Hide overrides" : "Show overrides"}><i onClick={() => setShowOverrides(!showOverrides)} className={`hover:cursor-pointer fa-solid ${showOverrides ? 'fa-eye-slash' : 'fa-eye'} ml-1 text-green`}></i></abbr></label>
                        {showOverrides && 
                            <UserPermissionCustomisation 
                                propToHumanReadable={propToHumanReadable} 
                                userPermissions={permissionOverrides}
                                isTriState={true}
                                toggledOnColour="#308e63"
                            />
                        }        
                        <span className="text-sm text-[#8181A5]">Double click a switch to set a permission back to inheriting from its role.</span>

                        <span className="font-bold mt-4">Set limits of Orders</span>
                        <ul>
                            <li className="mb-2">
                                <LimitDefinition 
                                    limitLabel="Purchase" 
                                    limitText="Enter max value user can send on Purchase Order" 
                                    onChanged={(newValue: number) => {if (newValue !== purchaseLimit.current) purchaseLimit.current = newValue;}}
                                    defaultValue={purchaseLimit.current}
                                />
                            </li>
                            <li className="mb-2">
                                <LimitDefinition 
                                    limitLabel="Sales" 
                                    limitText="Enter max value user can send on Sales Order" 
                                    onChanged={(newValue: number) => {if (newValue !== salesLimit.current) salesLimit.current = newValue;}}
                                    defaultValue={salesLimit.current}
                                />
                            </li>
                            <li className="mb-2">
                                <LimitDefinition 
                                    limitLabel="Quote" 
                                    limitText="Enter max value user can send on Quote" 
                                    onChanged={(newValue: number) => {if (newValue !== quoteLimit.current) quoteLimit.current = newValue;}}
                                    defaultValue={quoteLimit.current}
                                />
                            </li>
                            <li className="mb-2">
                                <LimitDefinition 
                                    limitLabel="Invoice" 
                                    limitText="Enter max value user can send on Invoice" 
                                    onChanged={(newValue: number) => {if (newValue !== invoiceLimit.current) invoiceLimit.current = newValue;}}
                                    defaultValue={invoiceLimit.current}
                                />
                            </li>
                        </ul>

                        <span className="font-bold mt-4">Quick Actions</span>
                        <ul>
                            <li>
                                <button 
                                    className="btn btn-secondary-green w-full md:w-72 lg:w-96 mb-2" 
                                    onClick={() => resetUserPassword()}>
                                        <i className="fa-solid fa-link mr-1"></i> Reset User Password
                                </button>
                            </li>
                            <li>
                                <button 
                                    className="btn btn-secondary-green w-full md:w-72 lg:w-96 mb-2" 
                                    onClick={() => sendWeeklyQuoteReport()}>
                                        <i className="fa-solid fa-link mr-1"></i> Send Weekly Quote Report
                                </button>
                            </li>
                        </ul>
                        <div className="flex flex-col md:flex-row">
                            <label htmlFor="userMaintenanceUserIsSuperUser" className="mr-4 font-semibold">Does this user have Super User Permissions?</label>
                            {/* On the original design, this was a textbox, but checkboxes tend to be more intuitive for yes/nos. */}
                            <input id="userMaintenanceUserIsSuperUser" type="checkbox" onChange={superPermissionChanged} checked={userHasSuperPermissions}></input>
                        </div>
                        <span className="text-sm text-[#8181A5]">This permission supersedes all allocated permissions, and grants access to all areas of the system.</span>
                        <div className="w-full md:w-28">
                            <button 
                                className="btn-primary bg-green pt-2 pb-2 pl-4 pr-4 w-full lg:w-96 md:w-28" 
                                onClick={saveUserChanges}>
                                    <i className="fa-solid fa-floppy-disk mr-1"></i>{newUser ? "Create" : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}