import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useKeyPress } from "../utils";
import { useScreen } from '../context';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { BasketItem, PurchaseOrder } from '../types';

import { fetchPurchaseOrder, fetchBulkProducts, createPurchaseOrder, updatePurchaseOrder } from '../api';
import CustomerAndSupplierNotes from '../components/widgets/newNoteModal';
import { Resizable } from 're-resizable';

import PurchaseOrderTemplate from '../components/pdfs/templates/purchaseOrder';

import {  
  calculatePurchaseOrderBasketTotals, 
  removeItemFromBasket,
  formatBasketForD3,
  valueIsNullOrZero,
  getExtension
} from '../components/basketUtils';

import SupplierSearchModal from '../components/suppliersAndCustomersSearchModal';
import ProductsSearchModal from '../components/productsSearchModal';

import { getDateInD3Format, getTimeInD3Format, unwrapD3DateToISO } from '../utils';

const NewPurchase = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const [basket, setBasket] = useState([] as BasketItem[]);
  const navigate = useNavigate();

  const [deliveryLocation, setDeliveryLocation] = useState("");
  const branches = ["Bournemouth Branch", "Hull Branch", "Sheffield Branch"];

  const [supplierData, setSupplierData] = useState(null as any);
  const [basketStats, setBasketStats] = useState({ totalQuoted: 0, vat: 0 });
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [suppliersModalOpen, setSuppliersModalOpen] = useState(false);
  const [productsModalOpen, setProductsModalOpen] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const [orderIdForUpdate, setOrderIdForUpdate] = useState("");

  const [deliveryFee, setDeliveryFee] = useState(null as null | string);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [noteModalOpen, setNoteModalOpen] = useState(false);

  const [orderData, setOrderData] = useState({} as PurchaseOrder);
  const [originalOrderData, setOriginalOrderData] = useState({} as PurchaseOrder);

  const { setScreenId, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`PO-${orderId || "NEW"}`)
  
  const clearEQSI = () => {
    setSupplierData(null);
    setBasket([]);
    setBasketStats({ totalQuoted: 0, vat: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {

  }
  useKeyPress('F1', backAction);

  const upAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== 0) setActiveBasketItem(basket[indexOfItem - 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowUp', upAction);

  useKeyPress('Escape', () => {
    if (productsModalOpen) setProductsModalOpen(false);
    if (suppliersModalOpen) setSuppliersModalOpen(false);
    if (noteModalOpen) setNoteModalOpen(false);
  });

  const downAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== basket.length - 1) setActiveBasketItem(basket[indexOfItem + 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowDown', downAction);

  useEffect(() => {
    async function fetchOrderData() {
      if (orderId === "NEW" || !orderId) return;

      let fetchedOrderData = await fetchPurchaseOrder(orderId);
      if  (!fetchedOrderData || fetchedOrderData.length === 0) return;

      let order = fetchedOrderData[0]
      setOrderData(order)
      setOriginalOrderData(order)
      
      setDeliveryFee(parseFloat(order["CARRIAGE.FEE"]).toFixed(2));
      setExpectedDeliveryDate(unwrapD3DateToISO(order["DELIVERY.DATE"]));
      setSupplierData({ ACCNO: order["SUPPLIER.CODE"], NAME: order["SU.NAME"] });
      setDeliveryLocation(order["DELIVER.TO"]);
      setOrderIdForUpdate(order.KEY);
      console.log(order);

      typeof order["PRODUCT.CODE"] === "string" && (order["PRODUCT.CODE"] = [order["PRODUCT.CODE"]]);
      typeof order["ORDER.QUANTITY"] === "string" && (order["ORDER.QUANTITY"] = [order["ORDER.QUANTITY"]]);
      typeof order["PRICE"] === "string" && (order["PRICE"] = [order["PRICE"]]);

      if (order["PRODUCT.CODE"] && order["PRODUCT.CODE"].length > 0) {
        let products = await fetchBulkProducts(order["PRODUCT.CODE"]);
        let basketData = order["PRODUCT.CODE"].map((productCode: string, index: number) => {
          return {
            product: products[index],
            quantity: parseInt(order["ORDER.QUANTITY"][index]),
            basketPrice: parseFloat(order["PRICE"][index]),
            basketPriceString: parseFloat(order["PRICE"][index]).toFixed(2),
            tax: 0
          }
        });

        console.log(basketData)
        setBasket(basketData);
        console.log(products);
      }
    }

    fetchOrderData();
  }, [orderId]);

  useEffect(() => {
    if (basket.length == 0) {
      setBasketStats({ totalQuoted: 0, vat: 0 });
      return
    }

    setPageHasDirtyForm(true);
    setBasketStats(calculatePurchaseOrderBasketTotals(basket));
  }, [basket]);

  useEffect(() => {
    if (!productsModalOpen) {
      let lastItemsQuantityInput = document.getElementById(`quantity-${basket.length - 1}`) as HTMLInputElement;
      if (lastItemsQuantityInput) lastItemsQuantityInput.focus();
    }
  }, [productsModalOpen]);

  const attemptCreatePurchaseOrder = async () => {
    if (!supplierData || !deliveryLocation  || basket.length === 0) return;
    console.log("Creating Purchase Order...");
    const formattedBasket = await formatBasketForD3(basket);
    let currentDateTime = new Date();

    const orderPayload = {
      "ORDER.DATE": getDateInD3Format(currentDateTime),
      "ORDER.TIME": getTimeInD3Format(currentDateTime),
      "DELIVER.TO": deliveryLocation,
      "DELIVERY.DATE": expectedDeliveryDate,
      "SUPPLIER.CODE": supplierData.ACCNO,
      "ORDER.QUANTITY": formattedBasket.productQuantities,
      "PRODUCT.CODE": formattedBasket.productCodes,
      "PRICE": formattedBasket.productPrices,
      "PO.COMMENTS": orderData["PO.COMMENTS"] || "Temp comment",
      "CARRIAGE.FEE": deliveryFee || "0.00",
    } as PurchaseOrder;

    const response = await createPurchaseOrder(orderPayload);
    console.log(response);

    if (!response.Success.includes("Error")) {
      navigate(-1);
      return;
    }

    addNotification(response.message, "error");
  }

  const attemptUpdatePurchaseOrder = async () => {
    if (!supplierData || !deliveryLocation  || basket.length === 0 || !orderId || !orderIdForUpdate) return;
    console.log("Creating Purchase Order...");
    const formattedBasket = await formatBasketForD3(basket);
    let currentDateTime = new Date();

    const originalValuePayload = {
      "DELIVER.TO": originalOrderData["DELIVER.TO"],
      "DELIVERY.DATE": originalOrderData["DELIVERY.DATE"],
      "ORDER.QUANTITY": originalOrderData["ORDER.QUANTITY"],
      "PRODUCT.CODE": originalOrderData["PRODUCT.CODE"],
      "PRICE": originalOrderData["PRICE"],
      "PO.COMMENTS": originalOrderData["PO.COMMENTS"],
      "CARRIAGE.FEE": originalOrderData["CARRIAGE.FEE"],
    }

    const newValuePayload = {
      "DELIVER.TO": deliveryLocation,
      "DELIVERY.DATE": expectedDeliveryDate.includes("-") ? getDateInD3Format(new Date(expectedDeliveryDate)) : expectedDeliveryDate,
      "ORDER.QUANTITY": formattedBasket.productQuantities,
      "PRODUCT.CODE": formattedBasket.productCodes,
      "PRICE": formattedBasket.productPrices,
      "PO.COMMENTS": orderData["PO.COMMENTS"] || "Temp comment",
      "CARRIAGE.FEE": deliveryFee || "0.00",
    } as any;

    const response = await updatePurchaseOrder(orderIdForUpdate, originalValuePayload, newValuePayload); 
    console.log(response.Success);

    if (!response.Success.includes("Error")) {
      navigate(-1);
      return;
    }
  
    addNotification(response.message, "error");
  }

  const handleKeyDownForBasketInputs = (key: string, index: number, inputKey: string) => {
    if (key === "ArrowDown") {
      let nextInput = document.getElementById(`${inputKey}-${index + 1}`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
      else {
        let addProductButton = document.getElementById("addProductButton");
        if (addProductButton) addProductButton.focus();
      }
    }
    if (key === "ArrowUp") {
      let prevInput = document.getElementById(`${inputKey}-${index - 1}`) as HTMLInputElement;
      if (prevInput) prevInput.focus();
    }
    if (key === "Enter" || key === "Tab") {
      console.log(key, inputKey, index);
      if (inputKey !== "discount") {
        let nextInputKey = "";
        switch (inputKey) {
          case "quantity":
            nextInputKey = "price";
            break;
          case "price":
            nextInputKey = "per";
            break;
          case "per":
            nextInputKey = "discount";
            break;
        }
        console.log("From key", inputKey, "to key", nextInputKey);

        let nextInput = document.getElementById(`${nextInputKey}-${index}`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
        else {
          let addProductButton = document.getElementById("addProductButton");
          if (addProductButton) addProductButton.focus();
        }
      }
      else {
        let nextInput = document.getElementById(`quantity-${index + 1}`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
        else {
          let addProductButton = document.getElementById("addProductButton");
          if (addProductButton) addProductButton.focus();
        }
      }
    }
  }

  const handleDownload = async () => {
    if (!supplierData || !deliveryLocation || !expectedDeliveryDate || !orderId || basket.length === 0 || !deliveryFee) return;

    setPdfDownloading(true);

    const invoicePayload = {
      purchaseOrderId: orderId,
      supplierName: supplierData.NAME,
      supplierAddress: supplierData.ADDRESS || ["303 Fake Street", "Fake Town", "Fake County", "FA1 1KE"],
      deliveryAddress: deliveryLocation,
      deliveryDate: expectedDeliveryDate,
      basket: basket,
      notes: orderData["PO.COMMENTS"],
      subtotal: basketStats.totalQuoted,
      vat: basketStats.vat,
      deliveryFee: deliveryFee,
    };

    const doc = <PurchaseOrderTemplate {...invoicePayload} />;
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `purchase_order_${orderId}.pdf`);
    setPdfDownloading(false);
  };

  return (
    <div className="flex flex-col gap-6 items-start w-full">
      <ProductsSearchModal 
        basket={basket}
        setBasket={setBasket}
        setActiveBasketItem={setActiveBasketItem}
        activeBasketItem={activeBasketItem}
        modalIsOpen={productsModalOpen}
        setModalOpen={setProductsModalOpen}
        screen="purchase"
      />

      <SupplierSearchModal
        setData={setSupplierData}
        modalIsOpen={suppliersModalOpen}
        setModalOpen={setSuppliersModalOpen}
        dataSource='Suppliers'
      />

      <div className="flex w-full items-end gap-4">
        {orderId == "NEW" 
          ? <>
              <h2 className="font-semibold text-2xl">New Purchase Order</h2>

              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/purchasing";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-primary disabled:opacity-50"
                onClick={attemptCreatePurchaseOrder}
                disabled={basket.length === 0 || !supplierData || !deliveryLocation}
              >
                Create Purchase Order
              </button>
            </>
          : <>
            <div>
              <p className="uppercase font-semibold text-sm">Purchase Order</p>
              <h2 className="font-bold text-2xl">{orderId}</h2>
            </div>


            <button
              className="btn btn-secondary ml-auto"
              onClick={() => {
                clearEQSI();
                window.location.href = "/purchasing";
              }}
            >
              Discard
            </button>

            <button
              className="btn btn-primary disabled:opacity-50"
              onClick={handleDownload}
              disabled={pdfDownloading}
            >
              <i className={`fa-solid ${pdfDownloading ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
              &nbsp;&nbsp;Download PDF
            </button>

            <button
              className="btn btn-primary"
              onClick={attemptUpdatePurchaseOrder}
              disabled={basket.length === 0 || !supplierData || !deliveryLocation}
            >
              Update Purchase Order
            </button>
          </>}
      </div>

      <div className="grid md:grid-cols-2 gap-6 w-full">
        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-user"></i>
            <h3 className="font-semibold uppercase text-sm">Supplier</h3>
          </div>

          <div className="p-4">
            <div className={`flex gap-2 items-center ${orderId === "NEW" && "cursor-pointer"}`} onClick={() => {
              orderId === "NEW" && setSuppliersModalOpen(true)
            }}>
              {supplierData
                ? <h2 className="font-light text-xl md:text-2xl">{supplierData.NAME}</h2>
                : <h2 className="font-semibold text-xl">Select a supplier</h2>
              }
              {orderId === "NEW" && <i className="fas fa-chevron-down"></i>}
            </div>
          </div>
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-map-location"></i>
            <h3 className="font-semibold uppercase text-sm">Branch</h3>
          </div>

          <div className="p-4">
            <select 
              className="input text-xl text-light outline-none"
              value={deliveryLocation}
              onChange={(e) => setDeliveryLocation(e.target.value)}
            >
              <option value="" disabled>Select a branch</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
          </div>

        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-6 w-full">
        <div className="flex-grow ">
          <div className="dashboard-card">
            <table className="w-full text-left table-auto">
              <thead>
                <tr className="table-blue">
                    {["Quantity", "Product", "Cost", "VAT", "Extension", ""].map((header: string, index: number) => (
                    <th
                      key={index}
                      className="px-1 py-4 text-sm"
                    >
                      <Resizable
                      enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                      minWidth={50}
                      className={`flex items-center px-2 w-full justify-center`}
                      >
                        <p className="shrink-0">{header}</p>
                      </Resizable>
                    </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {basket.map((item: BasketItem, index: number) => (
                  <tr 
                    key={index} 
                    onClick={() => setActiveBasketItem(item.product["PRODUCT.CODE"])}
                  >
                    <td className="max-w-12 px-2">
                      <input
                        type="number"
                        value={item.quantity || undefined}
                        id={`quantity-${index}`}
                        className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300"
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].quantity = parseInt(e.target.value);
                          setBasket(updatedBasket);
                        }}
                        onBlur={(e) => {
                          if (valueIsNullOrZero(e.target.value)) {
                            const updatedBasket = [...basket];
                            updatedBasket[index].quantity = 1;
                            setBasket(updatedBasket);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Tab") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "quantity")}
                        }
                      />
                    </td>
                    <td className="px-2">
                      <p className="text-sm font-semibold text-left-override">{item.product.DESCRIPTION}</p>
                    </td>
                    <td className="text-sm text-center px-2">
                      <input 
                        type="text"
                        step="0.01"
                        value={item.basketPriceString}
                        id={`price-${index}`}
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].basketPrice = parseFloat(e.target.value);
                          updatedBasket[index].basketPriceString = e.target.value;
                          setBasket(updatedBasket);
                        }}
                        onBlur={(e) => {
                          const updatedBasket = [...basket];
                          if (valueIsNullOrZero(e.target.value)) {
                            updatedBasket[index].basketPriceString = "1.00";
                            updatedBasket[index].basketPrice = 1;
                            setBasket(updatedBasket);
                          }
                          else updatedBasket[index].basketPriceString = parseFloat(e.target.value).toFixed(2);
                          setBasket(updatedBasket);
                        }}
                        className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300 max-w-24"
                        onKeyDown={(e) => {
                          if (e.key === "Tab") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "price")}
                        }
                      />
                    </td>
                    <td className="text-sm text-center">
                      {item.product["VAT.CODE"] === "1" 
                        ? <p>£{((item.product["VAT.CODE"] === "1" ? item.basketPrice * 0.2 : 0) * (item.quantity || 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        : <p className="text-center">Exempt</p>
                      }
                      
                    </td>
                    <td className="text-sm text-center">
                      £{getExtension(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td className="flex justify-center">
                      <div className="relative w-full">
                        {/* Ellipsis Button */}
                        <div className="flex justify-center w-full py-2 cursor-pointer group">
                          <i className="fas fa-ellipsis-v"></i>
                        </div>

                        {/* Outer Dropdown */}
                        <div className="absolute top-8 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 border border-gray-300 w-36"> 
                          <div className="relative hover-children-visible">
                            <div className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm">
                              <p>Purchase History</p>
                              <i className="fas fa-chevron-right"></i>
                            </div>

                            <div className="absolute top-0 left-36 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto pb-2 border border-gray-300 hidden-until-hover">
                              <table className="table w-full text-left">
                                <thead>
                                  <tr>
                                  <th className="border-b border-gray-400 text-center text-sm pl-4">Date</th>
                                  <th className="border-b border-gray-400 text-center text-sm px-1">Price</th>
                                  <th className="border-b border-gray-400 text-center text-sm px-1">Quantity</th>
                                  <th className="border-b border-gray-400 text-center text-sm pr-4">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">05/09/24</p></td>
                                  <td><p className="text-sm text-center px-1">£13.43</p></td>
                                  <td><p className="text-sm text-center px-1">12</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£161</p></td>
                                  </tr>
                                  <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">01/09/24</p></td>
                                  <td><p className="text-sm text-center px-1">£13.35</p></td>
                                  <td><p className="text-sm text-center px-1">100</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£1335</p></td>
                                  </tr>
                                  <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">28/08/24</p></td>
                                  <td><p className="text-sm text-center px-1">£14</p></td>
                                  <td><p className="text-sm text-center px-1">1</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£14</p></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> 
                          </div>

                          <div className="relative hover-children-visible">
                            <div className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm">
                              <p>Stock Levels</p>
                              <i className="fas fa-chevron-right"></i>
                            </div>

                            <div className="absolute top-0 left-36 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto pb-2 border border-gray-300 hidden-until-hover">
                              <table className="table w-full text-left">
                                <thead>
                                  <tr>
                                    <th className="border-b border-gray-400 text-center text-sm pl-4">Branch</th>
                                    <th className="border-b border-gray-400 text-center text-sm px-1 shrink-0 no-wrap">Stock</th>
                                    <th className="border-b border-gray-400 text-center text-sm px-1 pr-4">Incoming</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {["Bournemouth", "Hull", "Sheffield"].map((branch: string, index: number) => (
                                    <tr key={index}>
                                      <td><p className="text-sm text-center px-1 pl-4">{branch}</p></td>
                                      <td><p className="text-sm text-center px-1">{(item.product as any)[`${branch.toUpperCase()}`].toLocaleString()}</p></td>
                                      <td><p className="text-sm text-center px-1 pr-4">33</p></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div 
                            className="hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm"
                            onClick={() => removeItemFromBasket(item.product["PRODUCT.CODE"], basket, setBasket, setActiveBasketItem, activeBasketItem)}
                          >
                            <p>Remove Item</p>
                            <i className="fas fa-trash"></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="p-4">
              <button 
                className="btn btn-primary w-full flex items-center justify-center uppercase font-extrabold hover:bg-black focus:bg-black"
                onClick={() => setProductsModalOpen(true)}
                id="addProductButton"
              >
                <i className="fas fa-plus mr-2"></i>
                Add Product
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="dashboard-card">
            <div className="card-header bg-blue">
              <i className="fas fa-calculator"></i>
              <h3 className="font-semibold uppercase text-sm">Order Summary</h3>
            </div>
            
            <div className="p-4">
              <div className="flex justify-between items-center gap-2">
                <p>Subtotal:</p>
                <p>£{basketStats.totalQuoted.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
              <div className="flex justify-between items-center gap-2">
                <p>VAT (20%):</p>
                <p>£{(basketStats.vat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
 
              <div className="flex justify-between items-center gap-2 mt-8">
                <p>Est. Delivery:</p>
                <input 
                  type="date" 
                  className="input outline-none border border-gray-300 text-right pr-2 rounded-md" 
                  value={expectedDeliveryDate ? unwrapD3DateToISO(expectedDeliveryDate) : ""}
                  onChange={(e) => {
                    setPageHasDirtyForm(true);
                    setExpectedDeliveryDate(getDateInD3Format(new Date(e.target.value)))
                  }}
                />
              </div>
              <div className="flex justify-between items-center gap-2 mt-2">
                <p>Carriage/Shipping Fee:</p>
                <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                  <div className="border-gray-300 border-r px-1">
                    <p className="text-sm">£</p>
                  </div>
                    <div className="px-1">
                    <input 
                      type="text"
                      className="input text-lg text-light w-24 text-right pr-2 outline-none" 
                      value={deliveryFee || undefined}
                      onChange={(e) => setDeliveryFee(e.target.value)}
                      onBlur={(e) => {
                        let twoDecimalValue = parseFloat(e.target.value).toFixed(2);
                        setDeliveryFee(twoDecimalValue);
                      }}
                      onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        let dateInput = document.getElementById("expectedDeliveryDate") as HTMLInputElement;
                        dateInput.focus();
                        dateInput.showPicker();
                      }
                      }}
                    />
                    </div>
                </div>
              </div>
              <div className="flex justify-between items-center gap-2 mt-2">
                <p>VAT (20%):</p>
                <p>£{(deliveryFee ? parseFloat(deliveryFee) * 0.2 : 0).toFixed(2)}</p>
              </div>

              {expectedDeliveryDate && new Date(expectedDeliveryDate) < new Date() && 
                <p className="font-bold text-red-400 text-lg">Your delivery date is in the past!</p>
              }

              <div className="flex justify-between items-center gap-2 mt-10">
                <h3 className="font-semibold text-lg">Order Total:</h3>
                <p>£{(basketStats.totalQuoted + (deliveryFee ? parseFloat(deliveryFee) * 1.2 : 0) + basketStats.vat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div className="dashboard-card">
              <div className="card-header bg-blue">
                <i className="fas fa-sticky-note"></i>
                <h3 className="font-semibold uppercase text-sm">Order Notes</h3>
              </div>

              <CustomerAndSupplierNotes
                note={orderData["PO.COMMENTS"]}
                setNote={(note: string[]) => setOrderData({ ...orderData, "PO.COMMENTS": note }) }
                theme='blue'
              />
            </div>
          </div>

          <div className="dashboard-card">
            <div className="card-header bg-blue">
              <i className="fas fa-history"></i>
              <h3 className="font-semibold uppercase text-sm">Activity History</h3>
            </div>
            
            <div className="p-4">
              <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created draft Purchase Order.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPurchase;