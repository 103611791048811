import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LinkWithDirtyProtection from '../components/linkWrapper';

import LightMetricCard from '../components/widgets/lightMetricCard';
import { Tooltip } from 'react-tooltip'
import { useScreen } from '../context';
import { Order } from '../types'

import { listSalesOrders, searchSalesOrders } from '../api';

import { sortData } from '../components/tableSorting';
import { Resizable } from 're-resizable';
import Pagination from '../components/pagination';
import { formatNumber } from '../utils';
import PageTabs from '../components/pageTabs';
import SearchBar from '../components/searchBar';

const EqsiPage = () => {
  const tabOptions = ["All", "Quotes", "Sales Orders", "Invoiced", "Completed"];
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState([] as Order[]);
  const [salesOrderData, setSalesOrderData] = useState([] as Order[]);
  const [searchedData, setSearchedData] = useState([] as Order[]);
  const [tab, setTab] = useState(tabOptions[0]);
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => {
    async function fetchData() {
      try {
        const salesOrders = await listSalesOrders();
        console.log(salesOrders);
        setSalesOrderData(salesOrders);
        setFilteredAndSearchedData(salesOrders);
        setSearchedData(salesOrders);
        setFetching(false);
      }
      catch (e) {
        console.error(e);
      }
    }

    fetchData();
  }, []);

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("EQSI");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    setFilteredAndSearchedData(searchedData);
  }, [searchedData]);

  const defaultSortKey = "ORDER.NO";
  const [sortConfig, setSortConfig] = useState({ key: defaultSortKey, direction: 'asc' });
  const handleSort = (key: string) => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      setSortConfig({ key, direction: 'desc' });
    } else {
      setSortConfig({ key, direction: 'asc' });
    }
  };
  const sortedData = sortData({ defaultSortKey, data: filteredAndSearchedData, sortConfig });

  return (
    <div className="flex flex-col flex-grow gap-4">
      {!fetching && (
        <>
          <div className="grid md:grid-cols-4 gap-4">
            <LightMetricCard title="Total Quotes" count={4690} icon="square-quote" theme="green" />
            <LightMetricCard title="Sales Orders" count={281} icon="bag-shopping" theme="green" />
            <LightMetricCard title="Converted Quotes" count={14} icon="box-open-full" theme="green" />
            <LightMetricCard title="Completed" count={4690} icon="box-check" theme="green" />
          </div>

          <div className="flex justify-between items-center flex-wrap gap-4">
            <Tooltip id="my-tooltip" place='top-start' />
            <LinkWithDirtyProtection to="/sales/sales-order/NEW">
              <button 
                className="btn btn-green h-full"
                data-tooltip-id="my-tooltip" 
                data-tooltip-content={`Create a new Sales Order.`}
              >
                Start Order
              </button>
            </LinkWithDirtyProtection>

            <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={setTab} card={true} theme="green" />
            <SearchBar initialData={salesOrderData} setResults={setSearchedData} searchFunction={searchSalesOrders} childQuery=''/>
          </div>

          <div className="flex flex-col gap-4 dashboard-card rounded-lg  flex-grow">
            <table className="table-auto max-w-full">
              <thead>
                <tr className="table-green">
                    {[
                    { name: "Order Number", key: "ORDER.NO" },
                    { name: "Date", key: "ORDER.DATE" },
                    { name: "Amount", key: "ORDER.VAL" },
                    { name: "Delivery Date", key: "DELIVERY.DATE" }
                    ].map((header, index) => (
                    <th
                      key={index}
                      className={`text-left px-1 py-4 text-sm cursor-pointer ${index !== 3 ? 'border-r border-white' : 'text-center'}`}
                      onClick={() => handleSort(header.key)}
                    >
                      <Resizable
                      enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                      minWidth={125}
                      className="flex items-center justify-center gap-2 shrink-0 w-full"
                      >
                        <p className="shrink-0">{header.name}</p>
                        {sortConfig.key === header.key && (
                          <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                        )}
                      </Resizable>
                    </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {sortedData
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((salesOrder: Order, rowIndex: number) => (
                    <tr key={rowIndex}>
                      <td>
                        <Link className="flex gap-4 items-center justify-center pr-2 hover:underline pl-4" to={`/sales/sales-order/${salesOrder["ORDER.NO"].replace("gid://shopify/Order/", "")}`}>
                          <p className="font-semibold text-sm">
                            {salesOrder["ORDER.NO"].replace("gid://shopify/Order/", "")}
                          </p>
                        </Link>
                      </td>
                      <td>
                        <p className="text-sm">{salesOrder["ORDER.DATE"]}</p>
                      </td>
                      <td>
                        <p className="text-sm">£{salesOrder["ORDER.VAL"].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                      </td>
                      <td>
                        <p className="text-sm">{salesOrder["DELIVERY.DATE"]}</p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            
            <div className="px-4 pb-4 mt-auto">
              <Pagination 
                numberOfEntries={sortedData.length} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EqsiPage;