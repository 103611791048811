import React, { useEffect, useState } from 'react';
import { useScreen } from '../../context';

import ChartCard from '../../components/widgets/chartCard';
import SimpleMetricCard from '../../components/widgets/simpleMetricCard';
import DoubleMetricCard from '../../components/widgets/doubleMetricCard';
import TopProducts from '../../components/widgets/topProductsTable';
import BroadcastsCard from '../../components/widgets/broadcasts';

import DailyTotals from '../../components/widgets/dailyTotals';
import WeeklyTotals from '../../components/widgets/weeklyTotals';
import MonthlyTotals from '../../components/widgets/monthlyTotals';

import { fetchDashboardData } from '../../api';
import { Order } from '../../types';

import { groupOrders, processDataIntoWeeklyChanges } from './utils';

const DashboardPage = () => {
  const [fetching, setFetching] = useState(true);
  const [totalOrders, setTotalOrders] = useState({ thisWeek: 0, lastWeek: 0 });
  const [totalSales, setTotalSales] = useState({ thisWeek: 0, lastWeek: 0 });
  const [totalProfit, setTotalProfit] = useState({ thisWeek: 0, lastWeek: 0 });

  const [ordersThisYear, setOrdersThisYear] = useState<Order[]>([]);
  const [ordersLastYear, setOrdersLastYear] = useState<Order[]>([]);
  const [ordersThisWeek, setOrdersThisWeek] = useState<Order[]>([]);
  const [ordersLastWeek, setOrdersLastWeek] = useState<Order[]>([]);

  const { setScreenId } = useScreen();
  setScreenId("DASH");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching dashboard data...');
        const fetchResponse = await fetchDashboardData();
        if (!fetchResponse) throw new Error('No data returned from fetchDashboardData');
        
        const orders = fetchResponse.orders;

        const grouppedOrders = groupOrders(orders);
        const weeklyChanges = processDataIntoWeeklyChanges(grouppedOrders.ordersThisWeek, grouppedOrders.ordersLastWeek);

        setOrdersThisYear(grouppedOrders.ordersThisYear);
        setOrdersLastYear(grouppedOrders.ordersLastYear);
        setOrdersThisWeek(grouppedOrders.ordersThisWeek);
        setOrdersLastWeek(grouppedOrders.ordersLastWeek);
        setTotalOrders(weeklyChanges.totalOrders);
        setTotalProfit(weeklyChanges.totalProfit);
        setTotalSales(weeklyChanges.totalSales);
      
        console.log("Data processed.");
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl md:text-4xl font-bold">Dashboard</h1>

      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        <div className="grid md:grid-cols-3 gap-4 md:col-span-2">
          <SimpleMetricCard
            identifier='HDM'
            metric='Orders'
            count={totalOrders.thisWeek}
            change={totalOrders.thisWeek - totalOrders.lastWeek}
            period='week'
            icon='cart-shopping'
            fetching={fetching}
            theme="green"
          />

          <SimpleMetricCard
            identifier='HDM'
            metric='Sales'
            count={totalSales.thisWeek}
            change={totalSales.thisWeek - totalSales.lastWeek}
            period='week'
            icon='cash-register'
            fetching={fetching}
            theme="green"
          />

          <SimpleMetricCard
            identifier='HDM'
            metric='Profit'
            count={totalProfit.thisWeek}
            change={totalProfit.thisWeek - totalProfit.lastWeek}
            period='week'
            icon='arrow-trend-up'
            fetching={fetching}
            theme="green"
          />
        </div>

        <DoubleMetricCard />

        <DailyTotals ordersThisWeek={ordersThisWeek} ordersLastWeek={ordersLastWeek} fetching={fetching} />
        <WeeklyTotals ordersThisYear={ordersThisYear} fetching={fetching} />
        <MonthlyTotals ordersLastYear={ordersLastYear} ordersThisYear={ordersThisYear} defaultMetric={null} id={"monthly-chart"} fetching={fetching} />

      </div>
      
      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        <div className="md:col-span-2">
          <TopProducts ordersThisYear={ordersThisYear} fetching={fetching} />
        </div>
      
        <BroadcastsCard />
      </div>
    </div>
  );
}

export default DashboardPage;