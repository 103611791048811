import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import 'quill-table-ui/dist/index.css';
import TableModule from 'quill-table-ui';
import TableUI from 'quill-table-ui';

// Register the table modules with Quill
Quill.register({
  'modules/table': TableModule,
  'modules/tableUI': TableUI
}, true);

type Props = {
  content: string;
  setContent: (content: string) => void;
};

const MarkdownEditor = ({ content, setContent }: Props) => {
  const quillRef = useRef(null);
  const quillInstance = useRef<Quill | null>(null); // Ref to store the Quill instance

  useEffect(() => {
    // Initialize Quill only if it hasn't been initialized yet
    if (quillRef.current && !quillInstance.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
            ['clean'],                                        // remove formatting button
            ['table'],                                        // Table button added to toolbar
          ],
          table: true, // Enable table module
          tableUI: true // Enable table UI module for additional table management
        }
      });

      // Load initial content (including tables) using dangerouslyPasteHTML
      if (content) {
        quillInstance.current.clipboard.dangerouslyPasteHTML(content);
      }

      // Listen for text changes and update `setContent`
      quillInstance.current.on('text-change', () => {
        const html = quillInstance.current?.root.innerHTML || '';
        setContent(html);
      });
    }
  }, [setContent]);

  // Update editor content if `content` prop changes
  useEffect(() => {
    if (quillInstance.current && content !== quillInstance.current.root.innerHTML) {
      quillInstance.current.clipboard.dangerouslyPasteHTML(content);
    }
  }, [content]);

  return <div ref={quillRef} style={{ height: '580px' }} />;
};

export default MarkdownEditor;