export const colours = ["#2ab2f8", "#1E3A8A", "#34D399", "#FBBF24", "#818CF8", "#F472B6", "#10B981", "#3B82F6", "#F59E0B", "#6366F1", "#F472B6", "#34D399", "#F87171", "#FBBF24", "#818CF8", "#F59E0B", "#6366F1", "#10B981", "#3B82F6"];

export const yAxisFormatter = (value: number, metric: string) => {
  let leadingString = "";
  if (metric === "Sales" || metric === "Profit") {
    leadingString += "£";
  }

  if (value >= 1000000) {
    return `${leadingString}${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `${leadingString}${(value / 1000).toFixed(0)}K`;
  }
  return `${leadingString}${value}`;
};

export const CustomLegend = ({ payload }: any) => {
  return (
    <div className="flex gap-4 justify-center flex-wrap">
      {payload.map((entry: any, index: number) => (
        <div key={`item-${index}`} className="flex gap-2 items-center">
          <div className="w-4 h-4" style={{ backgroundColor: colours[index] }}></div>
          <span className="font-semibold text-sm">{entry}</span>
        </div>
      ))}
    </div>
  );
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  lineNames: string[];
  metric: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ active = false, payload, label, lineNames, metric }) => {
  if (active && payload && payload.length) {
    return (
      <div 
        className="custom-tooltip w-auto rounded-lg" 
        style={{
          position: 'absolute', 
          top: '230px', 
          left: '0%', 
          transform: 'translateX(60%)', 
          backgroundColor: '#fff', 
          border: '1px solid #ccc', 
          padding: '10px', 
          borderRadius: '4px',
          whiteSpace: 'nowrap' // Ensure no wrapping occurs
        }}
      >
        <div>
          <span className="label font-semibold">{`${label}`}</span>
        </div>
        {payload
          .filter((entry) => entry.value !== 0)
          .map((entry: any, index: number) => (
          <div key={`item-${index}`} className="flex gap-2">
            <span>{entry.name}:</span>
            <span>{metric !== "Orders" && '£'}{entry.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};