import React, { act, useEffect, useState } from 'react';
import { useScreen } from '../context';
import { Link } from 'react-router-dom';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PageTabs from '../components/pageTabs';
import SearchBar from '../components/searchBar';
import Pagination from '../components/pagination';

import { fetchProductData } from "../utils";
import { Product } from '../types';

import { searchProducts, updateProduct } from '../api';
import { Resizable } from 're-resizable';
import { sortData } from '../components/tableSorting';

const ProductPage = () => {
  const tabOptions = ["All Products", "Low Stock", "Product Categories"];
  const [tab, setTab] = useState(tabOptions[0] as string);
  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [searchedData, setSearchedData] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const [filterCategories, setFilterCategories] = useState([] as {title: string, enabled: boolean}[]);
  const [activeCategory, setActiveCategory] = useState('Categories' as string);
  const [newCategory, setNewCategory] = useState('' as string);

  const [filterBrands, setFilterBrands] = useState([] as {title: string, enabled: boolean}[]);

  const defaultSortKey = "DESCRIPTION";
  const [sortConfig, setSortConfig] = useState({ key: defaultSortKey, direction: 'asc' });
  const handleSort = (key: string) => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      setSortConfig({ key, direction: 'desc' });
    } else {
      setSortConfig({ key, direction: 'asc' });
    }
  };
  const sortedData = sortData({ defaultSortKey, data: filteredAndSearchedProducts, sortConfig });

  useEffect(() => {
    setFilteredAndSearchedProducts(searchedData
      .filter((product: Product) => {
        const categoryFilterApplied = filterCategories.some(category => category.enabled);
        const brandFilterApplied = filterBrands.some(brand => brand.enabled);

        const categoryMatch = categoryFilterApplied
          ? filterCategories.some(category => category.enabled && category.title === product['PGROUP.DESC'])
          : true;

        const brandMatch = brandFilterApplied
          ? filterBrands.some(brand => brand.enabled && brand.title === product['SUPPLIER'])
          : true;

        return categoryMatch && brandMatch
      })
    );
  }, [filterCategories, filterBrands, searchedData]);

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("PRODUCTS");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        if (!products || products.length === 0) {
          console.error('No products found');
          return;
        }
        const uniqueCategories = Array.from(new Set(products.map(product => product['PGROUP.DESC'])));
        const uniqueBrands = Array.from(new Set(products.map(product => product['SUPPLIER'])));
        setFilterCategories([...uniqueCategories].map(category => ({ title: category, enabled: false })));
        setFilterBrands([...uniqueBrands].map(brand => ({ title: brand, enabled: false })));

        setSearchedData(products);
        setProductData(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  const removeProductCategory = async (productCode: string) => {
    const OriginalValue = { "PGROUP.CODE": activeCategory };
    const UpdatedValue = { "PGROUP.CODE": "" };
    await updateProduct(productCode, OriginalValue, UpdatedValue);
    const updatedProducts = productData.map(product => {
      if (product["PRODUCT.CODE"] === productCode) {
        return { ...product, "PGROUP.CODE": "" };
      }
      return product;
    });
    setProductData(updatedProducts);
  }

  return (
    <div className="flex flex-col flex-grow gap-4">
      
      {fetching && 
        <div className="flex justify-center items-center h-full">
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
        </div>
      }

      {!fetching && <>
        {tab !== "Product Categories" 
          ? <>
              <div className="grid md:grid-cols-3 gap-4">
                <LightMetricCard title="Total Products" count={productData.length} icon="warehouse-full" />
                <LightMetricCard title="Unpublished" count={0} icon="clock"  />
                <LightMetricCard 
                  title="Low Stock" 
                  count={productData.filter(product => product["Total Stock"] < 5).length}
                  icon="circle-exclamation" 
                />
              </div>

              <div className="flex justify-between items-center flex-wrap gap-4">
                <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={setTab} card={true} />
                <SearchBar initialData={productData} setResults={setSearchedData} searchFunction={searchProducts} childQuery=''/>
              </div>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col gap-4 dashboard-card rounded-lg overflow-hidden flex-grow">
                  <table className="table-auto max-w-full">
                    <thead>
                      <tr className="table-blue">
                          {[
                          { name: "Product", key: "DESCRIPTION" },
                          { name: "Supplier", key: "SUPPLIER" },
                          { name: "List Price", key: "LIST.PRICE" },
                          { name: "Cost Price", key: "COST.PRICE" },
                          { name: "Stock", key: "Total Stock" }
                          ].map((header, index) => (
                          <th
                            key={index}
                            className="cursor-pointer"
                            onClick={() => handleSort(header.key)}
                          >
                            <Resizable
                            enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                            minWidth={125}
                            className={`flex items-center gap-2 shrink-0 w-full ${index !== 0 && "justify-center"}`}
                            >
                            <p className="shrink-0">{header.name}</p>
                            {sortConfig.key === header.key && (
                              <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                            )}
                            </Resizable>
                          </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sortedData
                        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                        .map((product: Product, rowIndex: number) => (
                          <tr key={rowIndex}>
                            <td>
                              <Link className="flex gap-4 items-center pr-2 hover:underline pl-4" to={`/product/${product["PRODUCT.CODE"]}`}>
                                <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="w-14 h-14 rounded-md" />
                                <div className="text-left-override">
                                  <p className="font-semibold text-sm">{product.DESCRIPTION.length > 45 ? product.DESCRIPTION.slice(0, 45) + "..." : product.DESCRIPTION}</p>
                                  <p className="text-xs">{product["PGROUP.DESC"]}</p>
                                </div>
                              </Link>
                            </td>
                            <td><p className="text-sm px-1">{product["SUPPLIER"]}</p></td>
                            <td><p className="text-sm px-1">£{product["LIST.PRICE"]}</p></td>
                            <td><p className="text-sm px-1">£{product["COST.PRICE"]}</p></td>
                            <td><p className="text-sm px-1 pr-4">{product["Total Stock"]}</p></td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  
                  <div className="px-4 pb-4 mt-auto">
                    <Pagination 
                      numberOfEntries={sortedData.length} 
                      currentPage={currentPage} 
                      setCurrentPage={setCurrentPage}
                      itemsPerPage={itemsPerPage}
                    />
                  </div>
                </div>

                <div>
                  <div className="dashboard-card rounded-lg overflow-hidden sticky top-10">
                    <div className="card-header bg-blue">
                      <i className="fa-solid fa-filter"></i>
                      <h2>Filters</h2>
                    </div>

                    <div className="p-4">
                      <div className="pb-2">
                        <h3 className="text-lg font-semibold pl-1">Categories</h3>
                      </div>
                      <div className="flex flex-col gap-1 pl-1">
                        {filterCategories.map((category, index) => (
                          <div key={category.title} className="flex items-center gap-2">
                            <input 
                              type="checkbox"
                              className="checkbox"
                              id={category.title}
                              checked={category.enabled}
                              onChange={(e) => {
                                setFilterCategories(prev => {
                                  const newFilters = [...prev];
                                  newFilters[index].enabled = e.target.checked;
                                  return newFilters;
                                });
                              }}
                            />
                            <label htmlFor={category.title}>{category.title}</label>
                          </div>
                        ))}
                      </div>

                      <div className="mt-4 pb-2">
                        <h3 className="text-lg font-semibold pl-1">Brands</h3>
                      </div>
                      <div className="flex flex-col gap-1 pl-1">
                        {filterBrands.map((brand, index) => (
                          <div key={brand.title} className="flex items-center gap-2">
                            <input 
                              type="checkbox"
                              className="checkbox"
                              id={brand.title}
                              checked={brand.enabled}
                              onChange={(e) => {
                                setFilterBrands(prev => {
                                  const newFilters = [...prev];
                                  newFilters[index].enabled = e.target.checked;
                                  return newFilters;
                                });
                              }}
                            />
                            <label htmlFor={brand.title}>{brand.title}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            : <>
              <PageTabs 
              tabs={["Categories", ...filterCategories.map(category => category.title)]}
              activeTab={activeCategory}
              setActiveTab={setActiveCategory}
              card={true} 
              />

              {activeCategory === "Categories" 
                ? <>
                    <div className="dashboard-card rounded-lg overflow-hidden flex-grow">
                      <table className="table-auto w-full">
                        <thead>
                          <tr className="table-blue">
                            <th>Category</th>
                            <th>Products</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterCategories.map((category, index) => (
                            <tr key={index}>
                              <td>{category.title || "Unassigned"}</td>
                              <td>{productData.filter(product => product["PGROUP.DESC"] === category.title).length}</td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>

                    <div className="dashboard-card rounded-lg">
                      <div className="card-header bg-blue">
                        <i className="fa-solid fa-plus"></i>
                        <h2>Create New Category</h2>
                      </div>
                      <div className="flex flex p-4 items-center gap-4">
                        <input 
                          type="text" 
                          className="input w-full border border-gray-300 rounded-md h-10 p-2"
                          placeholder="Enter category name..."
                          value={newCategory}
                          onChange={(e) => setNewCategory(e.target.value)}
                        />
                        <button 
                          className="btn btn-primary"
                          onClick={() => {
                            setFilterCategories(prev => [...prev, { title: newCategory, enabled: false }]);
                            setNewCategory('');
                          }}
                        >Create Category</button>
                      </div>
                    </div>
                  </>
                : <div className="dashboard-card rounded-lg overflow-hidden flex-grow">
                    <table className="table-auto w-full">
                      <thead>
                        <tr className="table-blue">
                          <th>Product</th>
                          <th>Supplier</th>
                          <th>List Price</th>
                          <th>Cost Price</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productData
                          .filter(product => product["PGROUP.DESC"] === activeCategory)
                          .map((product: Product, rowIndex: number) => (
                            <tr key={rowIndex}>
                              <td>
                                <Link className="flex gap-4 items-center pr-2 hover:underline pl-4" to={`/product/${product["PRODUCT.CODE"]}`}>
                                  <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="w-14 h-14 rounded-md" />
                                  <div className="text-left-override">
                                    <p className="font-semibold text-sm">{product.DESCRIPTION.length > 45 ? product.DESCRIPTION.slice(0, 45) + "..." : product.DESCRIPTION}</p>
                                    <p className="text-xs">{product["PGROUP.DESC"]}</p>
                                  </div>
                                </Link>
                              </td>
                              <td><p className="text-sm px-1">{product["SUPPLIER"]}</p></td>
                              <td><p className="text-sm px-1">£{product["LIST.PRICE"]}</p></td>
                              <td><p className="text-sm px-1">£{product["COST.PRICE"]}</p></td>
                              <td>
                                <button 
                                  className="btn bg-red-400 text-white"
                                  onClick={() => removeProductCategory(product["PRODUCT.CODE"])}
                                >Remove Category</button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
              }
            </>
        }
      </>}
    </div>
  );
}

export default ProductPage;