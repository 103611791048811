const DoubleMetricCard = () => {
  return (
    <div className="dashboard-card">
      <div className="card-header bg-green">
        <i className="fas fa-user"></i>
        <h2>My Numbers</h2>
        <i className="cursor-pointer fa-regular fa-ellipsis-vertical ml-auto" ></i>
      </div>

      <div className="grid grid-cols-3 gap-4 p-4 text-center">
        <div>
          <h3 className="font-bold text-xl md:text-4xl">12</h3>
          <p className="text-muted mt-1">Orders</p>
        </div>

        <div>
          <h3 className="font-bold text-xl md:text-4xl">15,121</h3>
          <p className="text-muted mt-1">Sales</p>
        </div>

        <div>
          <h3 className="font-bold text-xl md:text-4xl">281</h3>
          <p className="text-muted mt-1">Branches</p>
        </div>
      </div>
    </div>
  )

}

export default DoubleMetricCard;