import React, { useEffect, useState } from 'react'
import { useEcommerceContext } from './context'
import { Link } from 'react-router-dom'

const EcommerceNav = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [basketPreviewOpen, setBasketPreviewOpen] = useState(false);

  return <>
    <div className="border-b border-gray-200 py-4 bg-white sticky top-0 z-50">
      <div className="container mx-auto flex items-center gap-6">
        <Link to="/ecommerce">
          <img src="/hdm-logo.png" alt="Ecommerce" className="h-14" />
        </Link>

        <Link to="#" className="font-bold text-sm ml-6"><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Solar Products</Link>
        <Link to="#" className="font-bold text-sm"><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Help & Advice</Link>
        <Link to="#" className="font-bold text-sm">Installer Locator</Link>
        <Link to="#" className="font-bold text-sm">News</Link>
        <Link to="#" className="font-bold text-sm">Contact</Link>
        <Link to="#" className="font-bold text-sm">Trade</Link>
        <Link to="#" className="font-bold text-sm">Careers</Link>

        <div className="ml-auto flex items-center gap-2 font-bold">
          <i className="fa-solid fa-phone"></i>
          <p>Call us: 0800&nbsp;0016&nbsp;802</p>
        </div>
        <i className="fa-solid fa-search"></i>
        <i className="fa-solid fa-user"></i>
        <div className="relative cursor-pointer" onClick={() => setBasketPreviewOpen(true)}>
          <i className="fa-solid fa-shopping-cart"></i>
          {basket.length > 0 && <div className="absolute -top-3 -right-4 border border-black text-black bg-white font-extrabold text-sm px-1 rounded-full">{basket.length}</div>}
        </div>
      </div>
    </div>

    <div 
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end transition-opacity duration-300 ${basketPreviewOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
      style={{marginTop: "129px", height: "calc(100% - 129px)"}}
    >
      <div className={`bg-white p-4 max-w-[450px] transform transition-transform duration-300 ${basketPreviewOpen ? 'translate-x-0' : 'translate-x-full'} flex flex-col`}>
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-2xl">Basket</h2>
        <i className="fa-solid fa-times cursor-pointer" onClick={() => setBasketPreviewOpen(false)}></i>
      </div>
      <div className="mt-4">
        {basket.map((item, index) => (
        <div key={index} className="flex items-center gap-4">
          <img src={item.product["PICT.FLAG"]} alt={item.product.DESCRIPTION} className="h-20" />
          <div>
          <p className="font-bold">{item.product.DESCRIPTION}</p>
          <p>£{item.basketPrice} x {item.quantity}</p>
          </div>
        </div>
        ))}
      </div>

      <Link 
        className="mt-4 bg-black text-white p-2 mt-auto text-center"
        to="/ecommerce/checkout"
      >
        Checkout
      </Link>

      

      <button 
        className="mt-2 border border-black p-2"
        onClick={() => {
        setBasket([])
        setBasketPreviewOpen(false)
        }}
      >
        Clear Basket
      </button>
      </div>
    </div>
  </>
}

export default EcommerceNav