import React, { useState, useEffect, Key } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchSupplier, createSupplier, updateSupplier, updateSupplierBankInfo } from '../api';

import CustomerAndSupplierNotes from '../components/widgets/newNoteModal';
import SimpleMetricCard from '../components/widgets/simpleMetricCard';
import BackButton from '../components/backLink';

import { ContactData, Supplier } from '../types';
import ContactsGrid from '../components/contactsGrid';
import { contactsData } from '../dummyData';

const SuppliersCard = () => {
  const { id: supplierId } = useParams<{ id: string }>();
  const newSupplier = supplierId === "new";

  const tabOptions = ["Overview", "Financials", "Contacts"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [fetchingData, setFetchingData] = useState(true);

  const { setScreenId, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`PO- ${supplierId}`)

  const [originalData, setOriginalData] = useState({} as Supplier);
  const [supplierData, setSupplierData] = useState({} as Supplier);

  const [contacts, setContacts] = useState<ContactData[]>(contactsData);

  useEffect(() => {
    if (newSupplier || !supplierId) {
      setFetchingData(false);
      return
    }
    
    const fetchData = async () => {
      try {
        const data = await fetchSupplier(supplierId);
        if (!data.length) {
          addNotification("Supplier not found.", "error");
          return;
        }

        setSupplierData(data[0]);
        setOriginalData(data[0]);
        console.log(data);
      } catch (error) {
        console.error('There was a problem fetching accounts:', error);
      }
      setFetchingData(false);
    };

    fetchData();
  }, [supplierId]);

  const inputVariable = (label: string, valueKey: keyof Supplier) => {
    return <>
      <div className="label">{label}</div>
      <input 
        type="text" 
        className="input outline-none" 
        placeholder={`${label}...`} 
        value={supplierData[valueKey]} 
        onChange={(e) => {
          setPageHasDirtyForm(true);
          setSupplierData({ ...supplierData, [valueKey]: e.target.value })
        }}
      />
    </>
  }

  const attemptSave = async () => {
    try {
      let createResponse = await createSupplier(supplierData);
      console.log(createResponse);
      
      if (!createResponse) {
        addNotification("There was a problem saving the supplier. Please try again.", "error");
        return;
      }
      setOriginalData(supplierData);
      setPageHasDirtyForm(false);
      addNotification("Supplier created successfully.", "success");
    } catch (error) {
      addNotification("There was a problem saving the supplier. Please try again.", "error");
      console.error('There was a problem saving the supplier:', error);
    }
  }

  const attemptUpdate = async () => {
    try {
      if (!supplierId) return;

      let originalSupplierPayload = {} as any;
      let updatedSupplierPayload = {} as any;

      for (let key in originalData) {
        if (originalData[key as keyof Supplier] !== supplierData[key as keyof Supplier]) {
          originalSupplierPayload[key] = originalData[key as keyof Supplier];
          updatedSupplierPayload[key] = supplierData[key as keyof Supplier];
        }
      }

      console.log(originalSupplierPayload, updatedSupplierPayload);

      if (!Object.keys(originalSupplierPayload) || Object.keys(originalSupplierPayload).length === 0) {
        addNotification("No changes detected.", "error");
        return;
      }

      let updateResponse = await updateSupplier(supplierId, originalSupplierPayload, updatedSupplierPayload);
      console.log(updateResponse);

      if (!updateResponse) {
        addNotification("There was a problem updating the supplier. Please try again.", "error");
        return;
      }

      setOriginalData(supplierData);
      setPageHasDirtyForm(false);
      addNotification("Supplier updated successfully.", "success");
    } catch (err: any) {
      console.log(err)
      addNotification(err.message, "error");
    }
  }

  if (fetchingData) {
    return (
      <div className="dashboard-card w-full h-full p-4 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-sharp fa-circle-notch fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      </div>
    );
  }

  return (
  <div className="flex flex-col gap-6 items-start">
    <div className="flex items-center gap-4 w-full">
      <div>
        <BackButton />

        <h2 className="font-semibold text-2xl">
          {newSupplier 
            ? "New Supplier"
            : supplierData.NAME
          }
        </h2>
      </div>
      

      {newSupplier 
        ? <>
            <button
              className="btn btn-secondary ml-auto"
              onClick={() => {
                window.location.href = "/maintenance";
              }}
            >
              Discard
            </button>

            <button
              className="btn btn-primary"
              onClick={attemptSave}
            >
              Create Supplier
            </button>
          </>
        : <button
            className="btn btn-primary ml-auto"
            onClick={ attemptUpdate}
            disabled={fetchingData || (JSON.stringify(originalData) === JSON.stringify(supplierData))}
          >
            Save
          </button>
      }
    </div>

    <div className="grid md:grid-cols-2 gap-4 w-full">
      <SimpleMetricCard
        identifier='Total'
        metric='Orders'
        count={0}
        change={null}
        period='year'
        icon='cart-shopping'
        fetching={fetchingData}
        theme='blue'
      />

      <SimpleMetricCard
        identifier='Total'
        metric='Spend'
        count={0}
        change={null}
        period='year'
        icon='cash-register'
        fetching={fetchingData}
        theme='blue'
      />
    </div>

    <div className="grid md:grid-cols-3 gap-6 w-full">
      <div className="col-span-2">
        <div className="dashboard-card">
          <div className="card-header bg-blue">
            {tabOptions.map((option, index) => (
              <button 
                key={index} 
                className={`px-2 pb-2 btn font-bold
                    ${tab === option 
                      ? 'bg-white text-blue' 
                      : 'text-white'}
                `} 
                onClick={() => setTab(option)}
              >
                {option}
              </button>
            ))}
          </div>

          {fetchingData 
            ? <div className="flex flex-col justify-center items-center flex-grow py-12">
                <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
              </div>
            : <div className="p-4">
                {tab === "Overview" && <>
                  <h3 className="font-semibold">Primary Details</h3>

                  <div className="form-container mt-4">
                    <div className="label">Supplier Code</div>
                    <p className="text-sm pl-3">{supplierId}</p>

                    {inputVariable("Supplier Name", "NAME")}

                    <div className="label">Address</div>
                    <textarea 
                      className="input outline-none" 
                      placeholder="Address..." 
                      rows={4}
                      value={typeof supplierData.ADDRESS === "string" 
                        ? supplierData.ADDRESS 
                        : supplierData.ADDRESS !== undefined
                          ? supplierData.ADDRESS.join('\n')
                          : undefined
                      }
                      onChange={(e) => {
                        setPageHasDirtyForm(true);
                        setSupplierData({ ...supplierData, ADDRESS: e.target.value.split('\n') })
                      }}
                    />

                    {inputVariable("VAT Number", "VAT")}
                    {inputVariable("A/C Number", "OUR.ACCOUNT")}
                    {inputVariable("Currency", "CURRENCY")}
                    {inputVariable("Lead Time", "LEAD")}
                    {inputVariable("Phone", "PHONE")}
                    {inputVariable("Email", "PURCH.EMAIL")}
                    {inputVariable("Contact", "CONTACT")}
                  </div>
                </>}

                {tab === "Financials" && <>
                  <h3 className="font-semibold">Bank Details</h3>

                  <div className="form-container mt-4">
                    {inputVariable("Bank Name", "BEN.NAME")}
                    {inputVariable("Bank Account", "BEN.ACCOUNT")}
                    {inputVariable("Bank Ref", "BEN.REF")}
                    {inputVariable("Bank Type", "BEN.TYPE")}

                    <h3 className="font-semibold mt-2">Financial Terms</h3>
                    <div />

                    {inputVariable("Settlement Terms", "SETTLEMENT")}
                    {inputVariable("Payment Terms", "SUPP.TERMS")}
                  </div>
                </>}

                {tab === "Contacts" && <ContactsGrid contacts={contacts} setContacts={setContacts}/>}
              </div>
          }
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-sticky-note"></i>
            <h3 className="font-semibold uppercase text-sm">Supplier Notes</h3>
          </div>
          
          <CustomerAndSupplierNotes
            note={supplierData.COMMENTS}
            setNote={(note: string[]) => {
              setPageHasDirtyForm(true);
              setSupplierData({ ...supplierData, COMMENTS: note })
            }}
            theme='blue'
          />
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-history"></i>
            <h3 className="font-semibold uppercase text-sm">Activity History</h3>
          </div>
          
          <div className="p-4">
            <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created supplier record.</p>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default SuppliersCard;