import { Page, Text, Document, StyleSheet, View, Image, Font } from '@react-pdf/renderer';
import { unwrapD3DateToISO } from '../../../utils';
import { BasketItem } from '../../../types';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
    fontSize: 12,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 25,
  },
  image: {
    height: 40,
  },
  headerText: {
    fontSize: 18,
    marginLeft: 10,
  },
  sectionHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 16,
  },
  miniHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  chartImage: {
    width: '100%',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  flexGrow: {
    flexGrow: 1,
  },
  imageParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentCard: {
    padding: 10,
    marginBottom: 10,
    border: 1,
    borderColor: '#d3d3d3',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    borderRadius: 10,
  },
  divider: {
    marginTop: '3px',
    borderBottom: 1,
    borderColor: '#000',
    width: '100%',
    height: 1,
  },
  mb5: { marginBottom: 5 },
  mt10: { marginTop: 10 },
  my10: { marginVertical: 10 },
  mb10: { marginBottom: 10 },
  mt70: { marginTop: 70 },
  tableHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 12,
    marginVertical: 6,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 1,
    borderColor: '#d3d3d3',
    paddingVertical: 4,
  },
  tableCell: {
    fontSize: 10,
    paddingHorizontal: 4,
  },
  productImage: {
    height: 30,
    width: 30,
    marginRight: 10,
    flexBasis: 30,
    flexShrink: 0,
  },
  productCell: {
    width: "40%",
    maxWidth: "40%",
  },
  productTextContainer: {
    flexGrow: 1,
    flexWrap: 'wrap',
    maxWidth: 175,
  },
  standardCell: {
    width: "15%",
  },
  flexWrap: {
    flexWrap: 'wrap',
  }
});

interface PurchaseOrderProps {
  purchaseOrderId: string;
  supplierAddress: string | string[];
  supplierName: string;
  deliveryAddress: string;
  deliveryDate: string;
  basket: BasketItem[];
  notes: string | string[];
  subtotal: number;
  vat: number;
  deliveryFee: string;
}

const PurchaseOrderTemplate: React.FC<PurchaseOrderProps> = ({
  purchaseOrderId,
  supplierAddress,
  supplierName,
  deliveryAddress,
  deliveryDate,
  basket,
  notes,
  subtotal,
  vat,
  deliveryFee,
}) => {
  console.log(basket);
  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <View style={styles.header}>
          <Image style={styles.image} src="/hdm-logo.png" />
        </View>

        <View style={[styles.row, styles.justifyBetween, styles.itemsCenter]}>
          <Text style={styles.sectionHeader}>Purchase Order #{purchaseOrderId}</Text>
            <Text>{new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</Text>
        </View>

        <View style={styles.divider} />

        <View style={[styles.row, styles.justifyBetween, styles.mt10]}>
          <View style={styles.flexGrow}>
            <Text style={styles.miniHeader}>Supplier</Text>
            <Text>{supplierName}</Text>
            {typeof supplierAddress === 'string' 
              ? <Text>{supplierAddress}</Text> 
              : supplierAddress.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))
            }
          </View>
          <View style={styles.flexGrow}>
            <Text style={styles.miniHeader}>Delivery</Text>
            <Text>{deliveryAddress}</Text>
          </View>
          <View style={styles.flexGrow}>
            <Text style={styles.miniHeader}>Billing</Text>
            <Text>40 Kingfisher Way</Text>
            <Text>Dinnington</Text>
            <Text>Sheffield</Text>
            <Text>S25 3AF</Text>
          </View>
        </View>

        <View style={[styles.divider, styles.mt10]} />

        <View style={[styles.row, styles.mt10]}>
          <Text style={[styles.tableCell, styles.tableHeader, styles.productCell]}>Product</Text>
          <Text style={[styles.tableCell, styles.tableHeader, styles.standardCell]}>Quantity</Text>
          <Text style={[styles.tableCell, styles.tableHeader, styles.standardCell]}>Price</Text>
          <Text style={[styles.tableCell, styles.tableHeader, styles.standardCell]}>Tax</Text>
          <Text style={[styles.tableCell, styles.tableHeader, styles.standardCell]}>Total</Text>
        </View>

        {/* Table Rows */}
        {basket
          .filter(item => item.quantity && item.quantity > 0)
          .map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={[styles.tableCell, styles.productCell, styles.row]}>
              <Image style={styles.productImage} src={item.product['PICT.FLAG'] || '/no-image.png'} />
              <Text style={styles.productTextContainer}>{item.product.DESCRIPTION}</Text>
            </View>
            <Text style={[styles.tableCell, styles.standardCell]}>{(item.quantity || 1)}</Text>
            <Text style={[styles.tableCell, styles.standardCell]}>
              £{item.basketPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Text>
            <Text style={[styles.tableCell, styles.standardCell]}>
              {item.product['VAT.CODE'] === "1" ? "20%" : "0%"}
            </Text>
            <Text style={[styles.tableCell, styles.standardCell]}>
              £{((item.product['VAT.CODE'] === "1" ? item.basketPrice * 1.2 : item.basketPrice) * (item.quantity || 1)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Text>
          </View>
        ))}

        <View style={[styles.divider, styles.my10]} />

        <View style={[styles.row, styles.justifyBetween]}>
          <View style={styles.flexGrow}>
            <Text style={styles.miniHeader}>Order Notes</Text>
            {typeof notes === 'string' 
              ? <Text>{notes}</Text> 
              : notes.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))
            }
          </View>

          <View style={{ width: "60px"}} />

          <View style={styles.flexGrow}>
            <Text style={[styles.miniHeader, styles.mb10]}>Order Summary</Text>

            <View style={[styles.row, styles.justifyBetween, styles.mb5]}>
              <Text>Subtotal:</Text>
              <Text>£{subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
            <View style={[styles.row, styles.justifyBetween, styles.mb5]}>
              <Text>VAT:</Text>
              <Text>£{vat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
            <View style={[styles.row, styles.justifyBetween, styles.mb5, styles.mt10]}>
              <Text>Delivery Date:</Text>
              <Text>{new Date(deliveryDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</Text>
            </View>
            <View style={[styles.row, styles.justifyBetween, styles.mb5]}>
              <Text>Carriage Fee:</Text>
              <Text>£{deliveryFee}</Text>
            </View>
            <View style={[styles.row, styles.justifyBetween]}>
              <Text>Carriage VAT:</Text>
              <Text>£{(parseFloat(deliveryFee) * 0.2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>

            <View style={[styles.divider, styles.my10]} />

            <View style={[styles.row, styles.justifyBetween]}>
              <Text>Total:</Text>
              <Text>£{(subtotal + (deliveryFee ? parseFloat(deliveryFee) * 1.2 : 0) + vat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
          </View>
         
        </View>

        <View style={[styles.divider, styles.mb10, styles.mt70]} />

        <Text style={styles.miniHeader}>HDM SOLAR (WHOLESALE) LTD</Text>
        <Text>40 Kingfisher Way, Dinnington</Text>
        <Text>Sheffield, S25 3AF</Text>
        <Text>admin@hdmsolar.co.uk</Text>
        <Text>www.hdmsolar.co.uk</Text>
      </Page>
    </Document>
  );
};

export default PurchaseOrderTemplate;