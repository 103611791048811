import React, { useState, useEffect, act } from 'react';
import { useParams } from 'react-router-dom';
import { useKeyPress } from "../utils";
import { useScreen } from '../context';

import { Resizable } from 're-resizable';

import { BasketItem, Customer, Order } from '../types';

import { fetchSalesOrder, fetchBulkProducts } from '../api';
import SuppliersAndCustomersSearchModal from '../components/suppliersAndCustomersSearchModal';

import { 
  handleGPupdate, 
  handleDiscountUpdate,
  calculateSalesOrderBasketTotals, 
  removeItemFromBasket,
  handlePriceUpdate,
  valueIsNullOrZero
} from '../components/basketUtils';

import ProductsSearchModal from '../components/productsSearchModal';

const SalesOrder = () => {
  const { id: salesOrderId } = useParams<{ id: string }>();

  const [basket, setBasket] = useState([] as BasketItem[]);

  const [deliveryLocation, setDeliveryLocation] = useState("");
  const branches = ["Customer Location A", "Customer Location B", "Customer Location C"];

  const [customerData, setCustomerData] = useState(null as null | Customer);
  const [basketStats, setBasketStats] = useState({ subtotal: 0, profit: 0, grossProfit: 0, vat: 0 });
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [customersModalOpen, setCustomersModalOpen] = useState(false);
  const [productsModalOpen, setProductsModalOpen] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);

  const [deliveryFee, setDeliveryFee] = useState(null as null | string);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");

  const [orderData, setOrderData] = useState({} as Order);

  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`SO-${salesOrderId}`)
  
  const clearEQSI = () => {
    setCustomerData(null);
    setBasket([]);
    setBasketStats({ subtotal: 0, profit: 0, grossProfit: 0, vat: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {;
  }
  useKeyPress('F1', backAction);

  useKeyPress('Escape', () => {
    if (productsModalOpen) setProductsModalOpen(false);
    if (customersModalOpen) setCustomersModalOpen(false);
    if (noteModalOpen) setNoteModalOpen(false);
  });

  useEffect(() => {
    async function fetchSalesOrderData() {
      if (salesOrderId === "NEW" || !salesOrderId) return;
      let fetchedOrderData = await fetchSalesOrder(salesOrderId);
      let order = fetchedOrderData[0]
      setOrderData(order)
      console.log(order);

      if (order["PRODUCT.CODE"] && order["PRODUCT.CODE"].length > 0) {
        let products = await fetchBulkProducts(order["PRODUCT.CODE"]);
        let basketData = order["PRODUCT.CODE"].map((productCode: string, index: number) => {
          products[index]["Total Stock"] = parseInt(products[index]["BOURNEMOUTH"]) + parseInt(products[index]["HULL"]) + parseInt(products[index]["SHEFFIELD"]);
          return {
            product: products[index],
            quantity: parseInt(order["Order Quant."][index]),
            basketPrice: parseFloat(order["Price"][index]),
            tax: 0
          }
        });

        console.log(basketData)
        setBasket(basketData);
        console.log(products);
      }
    }

    fetchSalesOrderData();
  }, [salesOrderId]);


  useEffect(() => {
    console.log(filteredAndSearchedProducts);
  }, [filteredAndSearchedProducts]);

  useEffect(() => {
    if (basket.length == 0) {
      setBasketStats({ subtotal: 0, profit: 0, grossProfit: 0, vat: 0 });
      return
    }

    setPageHasDirtyForm(true);
    setBasketStats(calculateSalesOrderBasketTotals(basket));
  }, [basket]);

  const handleKeyDownForBasketInputs = (key: string, index: number, inputKey: string) => {
    if (key === "ArrowDown") {
      let nextInput = document.getElementById(`${inputKey}-${index + 1}`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
      else {
        let addProductButton = document.getElementById("addProductButton");
        if (addProductButton) addProductButton.focus();
      }
    }
    if (key === "ArrowUp") {
      let prevInput = document.getElementById(`${inputKey}-${index - 1}`) as HTMLInputElement;
      if (prevInput) prevInput.focus();
    }
    if (key === "Enter" || key === "Tab") {
      console.log(key, inputKey, index);
      if (inputKey !== "gp") {
        let nextInputKey = "";
        switch (inputKey) {
          case "quantity":
            nextInputKey = "discount";
            break;
          case "discount":
            nextInputKey = "price";
            break;
          case "price":
            nextInputKey = "per";
            break;
          case "per":
            nextInputKey = "gp";
            break;
        }
        console.log("From key", inputKey, "to key", nextInputKey);

        let nextInput = document.getElementById(`${nextInputKey}-${index}`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
        else {
          let addProductButton = document.getElementById("addProductButton");
          if (addProductButton) addProductButton.focus();
        }
      }
      else {
        let nextInput = document.getElementById(`quantity-${index + 1}`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
        else {
          let addProductButton = document.getElementById("addProductButton");
          if (addProductButton) addProductButton.focus();
        }
      }
    }
  }
  

  return (
    <div className="flex flex-col gap-6 items-start w-full">
      <ProductsSearchModal 
        basket={basket}
        setBasket={setBasket}
        setActiveBasketItem={setActiveBasketItem}
        activeBasketItem={activeBasketItem}
        modalIsOpen={productsModalOpen}
        setModalOpen={setProductsModalOpen}
        screen="sale"
      />

      <SuppliersAndCustomersSearchModal 
        modalIsOpen={customersModalOpen}
        setModalOpen={setCustomersModalOpen}
        setData={setCustomerData}
        dataSource="Customers"
      />


      <div className="flex w-full items-end gap-4">
        {salesOrderId === "NEW"
          ? <>
              <h2 className="font-semibold text-2xl">New Sales Order</h2>

              <button
                className="btn btn-secondary-green ml-auto"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/sales";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-green"
                disabled={basket.length === 0 || !customerData || !deliveryLocation || !expectedDeliveryDate || new Date(expectedDeliveryDate) < new Date()}
              >
                Save as Quote
              </button>
            </>
          : <>
              <div>
                <p className="uppercase font-semibold text-sm">Sales Order</p>
                <h2 className="text-widget-green font-bold text-2xl">{salesOrderId}</h2>
              </div>

              <button
                className="btn btn-secondary-green ml-auto"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/sales";
                }}
              >
                Discard
              </button>

              <button className="btn btn-green">
                Save Quotation
              </button>
              <button className="btn btn-green">
                Create Order
              </button>
            </>
        }
      </div>

      <div className="grid md:grid-cols-2 gap-6 w-full">
        <div className="dashboard-card">
          <div className="card-header bg-green">
            <i className="fas fa-user"></i>
            <h3 className="font-semibold uppercase text-sm">Customer</h3>
          </div>

          <div className="p-4">
            <div className="flex gap-2 items-center cursor-pointer" onClick={() => setCustomersModalOpen(true)}>
              {customerData
                ? <h2 className="font-light text-xl md:text-2xl">{customerData.NAME}</h2>
                : <h2 className="font-semibold text-xl">Select a customer</h2>
              }
              <i className="fas fa-chevron-down"></i>
            </div>
            {customerData && 
              <h3 className="font-light text-sm">
                {typeof customerData["ADDRESS"] === "string" 
                  ? customerData["ADDRESS"] 
                  : customerData["ADDRESS"]
                    .filter((address: string) => address.length > 0)
                    .join(", ")}
              </h3>
            }
          </div>
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-green">
            <i className="fas fa-map-location"></i>
            <h3 className="font-semibold uppercase text-sm">Branch</h3>
          </div>

          <div className="p-4">
            <select 
              className="input text-xl text-light outline-none"
              value={deliveryLocation}
              onChange={(e) => setDeliveryLocation(e.target.value)}
            >
              <option value="" disabled>Select a branch</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
            {deliveryLocation && 
              <div className="flex justify-between items-center gap-2">
                <h3 className="font-light">123 Any Street, {deliveryLocation.replace("Branch", "")}</h3>
              </div>
            }
          </div>

        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-6 w-full">
        <div className="flex-grow ">
          <div className="dashboard-card">
            <table className="w-full text-left table-auto">
              <thead>
                <tr className="table-green">
                    {["Quantity", "Product", "List Price", "Discount %", "Price", "Per", "Unit Price", "Extension", "Cost", "GP %", ""].map((header: string, index: number) => (
                    <th
                      key={index}
                    >
                      <Resizable
                      enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                      minWidth={50}
                      className={`flex items-center px-2 w-full ${index !== 1 ? 'justify-center' : 'text-left'}`}
                      >
                        <p className="shrink-0">{header}</p>
                      </Resizable>
                    </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {basket.map((item: BasketItem, index: number) => (
                  <tr 
                    key={index} 
                    onClick={() => setActiveBasketItem(item.product["PRODUCT.CODE"])}
                  >
                    <td className="max-w-12 px-2">
                      <input
                        type="number"
                        value={item.quantity || undefined}
                        id={`quantity-${index}`}
                        className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300"
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].quantity = parseInt(e.target.value);
                          setBasket(updatedBasket);
                        }}
                        onBlur={(e) => {
                          if (valueIsNullOrZero(e.target.value)) {
                            const updatedBasket = [...basket];
                            updatedBasket[index].quantity = 1;
                            setBasket(updatedBasket);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "quantity")}
                        }
                      />
                    </td>
                    <td className="px-2">
                      <p className="text-sm font-semibold text-left-override">{item.product.DESCRIPTION}</p>
                    </td>
                    <td className="text-sm text-center">
                      £{item.product["LIST.PRICE"].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td className="text-sm text-center max-w-8 px-2">
                      <input 
                        type="string" 
                        value={item.discountString}
                        id={`discount-${index}`}
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].discountString = e.target.value;
                          handleDiscountUpdate(e, index, basket, setBasket)}
                        }
                        onBlur={(e) => {
                          const updatedBasket = [...basket];
                          if (valueIsNullOrZero(e.target.value)) updatedBasket[index].discountString = "0.00";
                          else updatedBasket[index].discountString = parseFloat(e.target.value).toFixed(2);
                          setBasket(updatedBasket);
                        }}
                        className="w-full text-center px-2 outline-none bg-gray-100 rounded-md border border-gray-300 max-w-16"
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "discount")
                        }}
                      />
                    </td>
                    <td className="text-sm text-center px-2">
                      <input 
                        type="text"
                        step="0.01"
                        value={item.basketPriceString}
                        id={`price-${index}`}
                        style={{ width: `${item.basketPrice.toString().length * 9 + 35}px` }}
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].basketPriceString = e.target.value;
                          handlePriceUpdate(parseFloat(e.target.value), index, basket, setBasket)
                        }}
                        onBlur={(e) => {
                          const updatedBasket = [...basket];
                          if (valueIsNullOrZero(e.target.value)) {
                            updatedBasket[index].basketPriceString = "1.00";
                            handlePriceUpdate(1, index, basket, setBasket)
                          }
                          else updatedBasket[index].basketPriceString = parseFloat(e.target.value).toFixed(2);
                          setBasket(updatedBasket);
                        }}
                        className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300"
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "price")}
                        }
                      />
                    </td>
                    <td className="text-sm text-center px-2">
                      <input 
                        type="number" 
                        value={item.per} 
                        id={`per-${index}`}
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].per = parseFloat(e.target.value);
                          setBasket(updatedBasket);
                        }}
                        onBlur={(e) => {
                          const updatedBasket = [...basket];
                          if (valueIsNullOrZero(e.target.value)) {
                            updatedBasket[index].per = 1;
                            setBasket(updatedBasket);
                          }
                        }}
                        className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300 max-w-16"
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "per")}
                        }
                      />
                    </td>

                    <td className="text-sm text-center">
                      £{(
                        (item.basketPrice/ (item.per || 1 ))
                      ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td className="text-sm text-center">
                      £{(
                        (parseFloat(item.basketPrice.toFixed(2)) / (item.per || 1 )) * (item.quantity || 0)
                      ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td className="text-sm text-center">
                      £{item.product["COST.PRICE"].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td className="text-sm text-center max-w-20 px-2">
                      <input 
                        type="number" 
                        value={item.gpString}
                        id={`gp-${index}`}
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].gpString = e.target.value;
                          handleGPupdate(e, index, basket, setBasket)}
                        }
                        onBlur={(e) => {
                          const updatedBasket = [...basket];
                          if (valueIsNullOrZero(e.target.value)) updatedBasket[index].gpString = "0.00";
                          else updatedBasket[index].gpString = parseFloat(e.target.value).toFixed(2);;
                          setBasket(updatedBasket);
                        }}
                        className="w-16 text-center px-2 outline-none bg-gray-100 rounded-md border border-gray-300"
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault()
                          handleKeyDownForBasketInputs(e.key, index, "gp")}
                        }
                      />
                    </td>
                    <td className="flex justify-center">
                      <div className="relative w-full">
                        {/* Ellipsis Button */}
                        <div className="text-green-700 flex justify-center w-full py-2 cursor-pointer group">
                          <i className="fas fa-ellipsis-v"></i>
                        </div>

                        {/* Outer Dropdown */}
                        <div className="absolute top-8 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 border border-gray-300 w-36"> 
                          <div className="relative hover-children-visible">
                            <div className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm">
                              <p>Sales History</p>
                              <i className="fas fa-chevron-right"></i>
                            </div>

                            <div className="absolute top-0 left-36 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto pb-2 border border-gray-300 hidden-until-hover">
                              <table className="table w-full text-left">
                                <thead>
                                  <tr>
                                  <th className="border-b border-gray-400 text-center text-sm pl-4">Date</th>
                                  <th className="border-b border-gray-400 text-center text-sm px-1">Price</th>
                                  <th className="border-b border-gray-400 text-center text-sm px-1">Quantity</th>
                                  <th className="border-b border-gray-400 text-center text-sm pr-4">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">05/09/24</p></td>
                                  <td><p className="text-sm text-center px-1">£13.43</p></td>
                                  <td><p className="text-sm text-center px-1">12</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£161</p></td>
                                  </tr>
                                  <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">01/09/24</p></td>
                                  <td><p className="text-sm text-center px-1">£13.35</p></td>
                                  <td><p className="text-sm text-center px-1">100</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£1335</p></td>
                                  </tr>
                                  <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">28/08/24</p></td>
                                  <td><p className="text-sm text-center px-1">£14</p></td>
                                  <td><p className="text-sm text-center px-1">1</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£14</p></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> 
                          </div>

                          <div className="relative hover-children-visible">
                            <div className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm">
                              <p>Stock Levels</p>
                              <i className="fas fa-chevron-right"></i>
                            </div>

                            <div className="absolute top-0 left-36 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto pb-2 border border-gray-300 hidden-until-hover">
                              <table className="table w-full text-left">
                                <thead>
                                  <tr>
                                    <th className="border-b border-gray-400 text-center text-sm pl-4">Branch</th>
                                    <th className="border-b border-gray-400 text-center text-sm px-1 shrink-0 no-wrap">Stock</th>
                                    <th className="border-b border-gray-400 text-center text-sm px-1 pr-4">Incoming</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {["Bournemouth", "Hull", "Sheffield"].map((branch: string, index: number) => (
                                    <tr key={index}>
                                      <td><p className="text-sm text-center px-1 pl-4">{branch}</p></td>
                                      <td><p className="text-sm text-center px-1">{(item.product as any)[`${branch.toUpperCase()}`].toLocaleString()}</p></td>
                                      <td><p className="text-sm text-center px-1 pr-4">33</p></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div 
                            className="hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm"
                            onClick={() => removeItemFromBasket(item.product["PRODUCT.CODE"], basket, setBasket, setActiveBasketItem, activeBasketItem)}
                          >
                            <p>Remove Item</p>
                            <i className="fas fa-trash"></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="p-4">
              <button 
                className="btn btn-green w-full flex items-center justify-center uppercase font-extrabold hover:bg-black focus:bg-black"
                onClick={() => setProductsModalOpen(true)}
                id="addProductButton"
              >
                <i className="fas fa-plus mr-2"></i>
                Add Product
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-calculator"></i>
              <h3 className="font-semibold uppercase text-sm">Order Summary</h3>
            </div>
            
            <div className="p-4">
              <div className="flex justify-between items-center gap-2">
                <p>Subtotal:</p>
                <p>£{basketStats.subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
              <div className="flex justify-between items-center gap-2 mt-2">
                <p>Profit:</p>
                <p>£{basketStats.profit.toFixed(2)} ({basketStats.grossProfit.toFixed(2)}%)</p>
              </div>
              <div className="flex justify-between items-center gap-2 mt-8">
                <p>Carriage/Shipping Fee:</p>
                <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                  <div className="border-gray-300 border-r px-1">
                    <p className="text-sm">£</p>
                  </div>
                    <div className="px-1">
                    <input 
                      type="text"
                      className="input text-lg text-light w-24 text-right pr-2 outline-none" 
                      value={deliveryFee || undefined}
                      onChange={(e) => setDeliveryFee(e.target.value)}
                      onBlur={(e) => {
                        let twoDecimalValue = parseFloat(e.target.value).toFixed(2);
                        setDeliveryFee(twoDecimalValue);
                      }}
                      onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        let dateInput = document.getElementById("expectedDeliveryDate") as HTMLInputElement;
                        dateInput.focus();
                        dateInput.showPicker();
                      }
                      }}
                    />
                    </div>
                </div>
                
              </div>
              <div className="flex justify-between items-center gap-2 mt-2">
                <p>Expected Delivery Date:</p>
                <input 
                  type="date"
                  id="expectedDeliveryDate"
                  className="input text-lg text-light border border-gray-300 text-right pr-2 rounded-md" 
                  value={expectedDeliveryDate}
                  onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                />
              </div>
                {expectedDeliveryDate && new Date(expectedDeliveryDate) < new Date() && 
                <p className="font-bold text-red-400 text-lg">Your delivery date is in the past!</p>
                }

              <div className="flex justify-between items-center gap-2 mt-8">
                <p>VAT (20%):</p>
                <p>£{(basketStats.vat + (deliveryFee ? parseFloat(deliveryFee) * 0.2 : 0)).toFixed(2)}</p>
              </div>

              <div className="flex justify-between items-center gap-2">
                <h3 className="font-semibold text-lg">Order Total:</h3>
                <p>£{(basketStats.subtotal + (deliveryFee ? parseFloat(deliveryFee) : 0) + (basketStats.vat + (deliveryFee ? parseFloat(deliveryFee) * 0.2 : 0))).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
            </div>
          </div>

          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-sticky-note"></i>
              <h3 className="font-semibold uppercase text-sm">Order Notes</h3>
            </div>

            {orderData['WAREHOUSE.COMMENTS'] && 
              typeof orderData['WAREHOUSE.COMMENTS'] === "object"
              ? orderData['WAREHOUSE.COMMENTS'].map((note, index) => (
                  <div key={index} className="p-4 border-t border-gray-200">
                    <p className="text-sm">{note}</p>
                  </div>
                ))
              : orderData['WAREHOUSE.COMMENTS'] !== undefined && <div className="p-4 border-t border-gray-200">
                  <p className="text-sm">{orderData['WAREHOUSE.COMMENTS']}</p>
                </div>
            }
            
            <div className="p-4">
              <button 
                className="btn btn-green w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
                onClick={() => setNoteModalOpen(true)}
              >
                <i className="fas fa-plus mr-2"></i>
                Add Note
              </button>
            </div>
          </div>

          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-history"></i>
              <h3 className="font-semibold uppercase text-sm">Activity History</h3>
            </div>
            
            <div className="p-4">
              <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created draft Purchase Order.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesOrder;