import { useState } from "react"
import { ContactData, Sort } from "../types"
import { getSortByKey, setSortOn, sort } from "../utils"
import ContactTab from "./contactTab"
import SearchBar from "./searchBar"
import { SortColumnArrow } from "./widgets/sortColumnArrow"

type ContactsGridProps = {
    contacts: ContactData[],
    setContacts: React.Dispatch<React.SetStateAction<ContactData[]>>,
    theme?: string
}

const ContactsGrid = ({contacts, setContacts, theme = "blue"}: ContactsGridProps) => {
    //-1 when tab not open
    const [currentContactIndex, setCurrentContactIndex] = useState(-1);
    const [contactSorts, setContactSorts] = useState<Sort<ContactData>[]>([]);
    const [searchedContacts, setSearchedContacts] = useState<ContactData[]>()

    return (
        <>
            {currentContactIndex === -1 &&
            <div className="flex flex-col gap-2">
                <SearchBar initialData={contacts} setResults={setSearchedContacts} searchFunction={(searchTerm: string): Promise<any> => {
                //VERY TEMPORARY. Replace with backend API call later.
                return (async () => 
                    contacts.filter((contact) => {
                        for (const prop in contact) {
                            if (contact[prop as keyof ContactData].toString().toLowerCase().includes(searchTerm.toLowerCase())) return true
                        }
                        return false
                    })
                )()
                }} childQuery={null} />
                <table className={`table-auto divide-y divide-gray-200 w-full`}>
                <thead>
                    <tr className={`table-${theme}`}>
                    <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'name'))}>Name<SortColumnArrow ascending={getSortByKey(contactSorts, 'name')?.asc}/></th>
                    <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'email'))}>Email<SortColumnArrow ascending={getSortByKey(contactSorts, 'email')?.asc}/></th>
                    <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'address'))}>Address<SortColumnArrow ascending={getSortByKey(contactSorts, 'address')?.asc}/></th>
                    <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'telephone'))}>Telephone<SortColumnArrow ascending={getSortByKey(contactSorts, 'telephone')?.asc}/></th>
                    <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'dateAdded'))}>Date Added<SortColumnArrow ascending={getSortByKey(contactSorts, 'dateAdded')?.asc}/></th>
                    <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'lastAmended'))}>Last Amended<SortColumnArrow ascending={getSortByKey(contactSorts, 'lastAmended')?.asc}/></th>
                    <th></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {contacts.length > 0 && sort(searchedContacts ? searchedContacts : contacts, contactSorts).map((contact, index) => 
                    <tr className='h-14 hover:bg-gray-100'key={contact.name + index.toString()}>
                        <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.name}</td>
                        <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.email}</td>
                        <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.address}</td>
                        <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.telephone}</td>
                        <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.dateAdded.toLocaleDateString()}</td>
                        <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.lastAmended.toLocaleString()}</td>
                        <td>
                        <button className="btn bg-red-400 text-white" onClick={() => {
                            if (window.confirm("Are you sure you want to delete this contact?")) {
                                contacts.splice(index, 1);
                                setContacts([...contacts]);
                            }
                            }}>Delete
                        </button>
                        </td>
                    </tr>
                    )}
                    {(contacts.length === 0 || (searchedContacts && searchedContacts.length === 0)) && <tr className='h-14'><td colSpan={7}>No contacts found. {(!searchedContacts || searchedContacts.length < 1) && <>Please add contacts with the button below.</>}</td></tr>}
                </tbody>
                </table>
                <button onClick={() => {
                    const newContacts: ContactData[] = [...contacts, {
                        name: '',
                        email: '',
                        address: '',
                        telephone: '',
                        dateAdded: new Date(),
                        lastAmended: new Date()
                    }];
                    setContacts(newContacts); 
                    setCurrentContactIndex(newContacts.length - 1);
                }} className={`w-full btn btn-primary ${theme === 'green' ? 'btn-green' : ''}`}><i className="fa-solid fa-plus mr-1 text-white"></i>Add</button>
            </div>
            }
            {currentContactIndex !== -1 &&
            <ContactTab contactData={contacts[currentContactIndex]} theme={theme} closeTab={() => setCurrentContactIndex(-1)} deleteContact={() => {
                contacts.splice(currentContactIndex, 1);
                setContacts([...contacts]);
            }}/>
            }
        </>
    )

}

export default ContactsGrid;