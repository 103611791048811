import MetricChangeHandler from "./metricChangeHandler";
import { formatNumber } from "../../utils";

type SimpleMetricCardProps = {
  identifier: string;
  count: number;
  change: number | null;
  period: string;
  metric: string;
  icon: string;
  fetching: boolean;
  theme?: string;
}

const SimpleMetricCard = ({ identifier, count, change, period, metric, icon, fetching, theme = "blue" }: SimpleMetricCardProps) => {

  const includeCurrencySymbol = metric === "Sales" || metric === "Profit";

  return (
    <div className="dashboard-card">
      <div className={`card-header bg-${theme} text-white`}>
        <i className={`fas fa-${icon} text-lg`} data-testid='icon-testid'></i>
        <h2>{identifier}&nbsp;{metric}&nbsp;this&nbsp;{period}</h2>
        <i className="cursor-pointer fa-regular fa-ellipsis-vertical ml-auto" ></i>
      </div>
      
      <div className="p-4 text-center h-full">
        {fetching
          ? <i className="fa-solid fa-spinner fa-spin text-xl"></i>
          : <>
              <h3 className="font-bold text-xl md:text-5xl">
                {includeCurrencySymbol && "£"}
                {formatNumber(count)}
              </h3>
      
              {change && change !== 0 &&
                <p className="text-muted text-sm">
                  {fetching
                    ? <i className="fa-solid fa-spinner fa-spin"></i>
                    : <MetricChangeHandler metric={metric} metricChange={change || 0} giveTextColor={true} />
                  }
                  &nbsp;{(!change || change < 0) ? "less" : "more"} than this time last {period}.
                </p>
              }
            </>
        }
      </div>
    </div>
  )

}

export default SimpleMetricCard;