import { Navigate } from 'react-router-dom';
import Sidenav from './components/sideNav';
import { useScreen } from './context';

const EXPIRATION_KEY = 'app_login_expiration';
//Where the login token gotten back from the server will be stored, to be checked by the server every page refresh.
const LOGIN_TOKEN = 'wt_user_login_token';

export const Login = () => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);
  localStorage.setItem(EXPIRATION_KEY, expirationDate.toISOString());
};

export const isLoggedIn = () => {
  const expiration = localStorage.getItem(EXPIRATION_KEY);

  if (!expiration) return false;

  const expirationDate = new Date(expiration);
  if (new Date() > expirationDate) {
    Logout();
    return false;
  }

  return true;
};

export const Logout = () => {
  localStorage.removeItem(EXPIRATION_KEY);
  window.location.href = '/login';
};

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const LoggedInWrapper: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { notifications } = useScreen();

  if (!isLoggedIn()) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <Sidenav />
      <div className="dashboard-content">
        <div className="bg-[#FAFAFA] h-full p-2 md:p-6 md:max-w-[calc(100vw-190px)]">
          {children}
        </div>
      </div>

      <div className="fixed top-4 right-4 flex flex-col items-end">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="dashboard-card mb-2 shadow-md slide-in"
          >
            <div className={`card-header ${notification.type === 'success' ? 'bg-green' : 'bg-red-500'}`}>
              <i className={`fas ${notification.type === 'success' ? 'fa-check-circle' : 'fa-exclamation-circle'}`}></i>
              <h3 className="font-semibold">{notification.type === 'success' ? 'Success' : 'Error'}</h3>
            </div>
            <div className="p-4">
              {notification.message}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};