
type SortColumnArrowProps = {
    ascending: boolean | undefined
}

const SortColumnArrow = ({ascending}: SortColumnArrowProps) => {
    //Due to Tailwind build cacheing issues, we have to do it like this, since it won't automatically compile the right classes otherwise
    if (ascending === undefined) return <></>
    if (ascending) return <i className={'ml-1 fa-solid fa-caret-up'}></i>
    else return <i className={'ml-1 fa-solid fa-caret-down'}></i>
}

export {SortColumnArrow}