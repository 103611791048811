import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoggedInWrapper } from './auth';
import { ScreenProvider } from './context';
import { EcommerceContext } from './pages/ecommerce/context';

import ExecuteCommandPage from './pages/execute-command';

import MaintenancePage from './pages/maintenance';
import PurchasingPage from './pages/purchasing';
import NewPurchase from './pages/purchaseOrder';
import DashboardPage from './pages/dashboard';
import UsersAndRolesPage from './pages/users';
import CustomersCard from './pages/customer';
import SuppliersCard from './pages/supplier';
import SalesOrder from './pages/salesOrder';
import ReportingPage from './pages/reports';
import ProductPage from './pages/products';
import LoginPage from './pages/login';
import TermsPage from './pages/terms';
import EqsiPage from './pages/eqsi';

import ProductCard from './components/productCard';
import PurchasingTrailer from './pages/purchasingtrailer';
import ChatWidget from './components/chatWidget';

import ConfigPage from './pages/config';
import NewBroadcastsPage from './pages/config/broadcasts';

import YearlySalesReport from './pages/reports/yearlySales';
import ExternalRepsReport from './pages/reports/reps';

import SalesHomePage from './pages/ecommerce';
import SalesProductsPage from './pages/ecommerce/products';
import CheckoutPage from './pages/ecommerce/checkout';

function App() {

  return (
    <ScreenProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          <Route path="/execute-command" element={<LoggedInWrapper><ExecuteCommandPage /></LoggedInWrapper>} />
          <Route path="/" element={<LoggedInWrapper><DashboardPage /></LoggedInWrapper>} />
          <Route path="/maintenance" element={<LoggedInWrapper><MaintenancePage /></LoggedInWrapper>} />
          <Route path="/purchasing" element={<LoggedInWrapper><PurchasingPage /></LoggedInWrapper>} />
          <Route path="/products" element={<LoggedInWrapper><ProductPage /></LoggedInWrapper>} />
          <Route path={`/customer/:id`} element={<LoggedInWrapper><CustomersCard /></LoggedInWrapper>} />
          <Route path={`/supplier/:id`} element={<LoggedInWrapper><SuppliersCard /></LoggedInWrapper>} />
          <Route path={`/product/:id`} element={<LoggedInWrapper><ProductCard /></LoggedInWrapper>} />
          <Route path={`/purchasing/po/:id`} element={<LoggedInWrapper><NewPurchase /></LoggedInWrapper>} />
          <Route path={`/sales/sales-order/:id`} element={<LoggedInWrapper><SalesOrder /></LoggedInWrapper>} />
          <Route path="/sales" element={<LoggedInWrapper><EqsiPage /></LoggedInWrapper>} />
          <Route path="/terms" element={<LoggedInWrapper><TermsPage /></LoggedInWrapper>} />
          <Route path="/purchasingTrailer" element={<LoggedInWrapper><PurchasingTrailer /></LoggedInWrapper>} />
          <Route path="/reporting" element={<LoggedInWrapper><ReportingPage /></LoggedInWrapper>} />
          <Route path="/users" element={<LoggedInWrapper><UsersAndRolesPage /></LoggedInWrapper>} />

          <Route path="/config" element={<LoggedInWrapper><ConfigPage /></LoggedInWrapper>} />
          <Route path="/config/broadcast-message" element={<LoggedInWrapper><NewBroadcastsPage /></LoggedInWrapper>} />

          <Route path="/reports/yearly-sales" element={<LoggedInWrapper><YearlySalesReport /></LoggedInWrapper>} />
          <Route path="/reports/external-reps" element={<LoggedInWrapper><ExternalRepsReport /></LoggedInWrapper>} />
          
          <Route path="/ecommerce" element={<EcommerceContext><SalesHomePage /></EcommerceContext>} />
          <Route path="/ecommerce/products" element={<EcommerceContext><SalesProductsPage /></EcommerceContext>} />
          <Route path="/ecommerce/checkout" element={<EcommerceContext><CheckoutPage /></EcommerceContext>} />
        </Routes>
      </Router>
      <ChatWidget />
    </ScreenProvider>
  );
}

export default App;
