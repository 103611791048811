import html2canvas from 'html2canvas';
import { Order } from '../../types';

export const exportChartToImage = async (chartElementId: string) => {
  const chartElement = document.getElementById(chartElementId);
  if (!chartElement) {
    throw new Error(`Element with id ${chartElementId} not found`);
  }
  const canvas = await html2canvas(chartElement);
  return canvas.toDataURL('image/png');
};

export const processSalesIntoSalesReps = async (ordersThisYear: Order[]) => {
  console.log('Processing sales into sales reps...');
  console.log(ordersThisYear);

  const salesRepData = [] as {name: string, orders: number, sales: number, profit: number}[];
  const preFormattedMonthlySales: { [key: string]: { [month: string]: number } } = {}

  for (const order of ordersThisYear) {
    let repName = order.OREP ? order.OREP : 'HDM Direct';
    

    if (repName !== 'HDM Direct') {
      let month = order.formattedDate.toLocaleDateString('en-GB', { month: 'short' });
      month = month.length > 3 ? month.slice(0, 3) : month;

      if (!preFormattedMonthlySales[repName]) {
        preFormattedMonthlySales[repName] = {};
      }

      if (!preFormattedMonthlySales[repName][month]) {
        preFormattedMonthlySales[repName][month] = 0;
      }

      preFormattedMonthlySales[repName][month] += order['ORDER.VAL'];
    }

    const salesRep = salesRepData.find((rep) => rep.name === repName);
    if (salesRep) {
      salesRep.orders++;
      salesRep.sales += order['ORDER.VAL'];
      salesRep.profit += order["MARGIN.SOLD"];
    } else {
      salesRepData.push({
        name: repName,
        orders: 1,
        sales: order['ORDER.VAL'],
        profit: order["MARGIN.SOLD"]
      });
    }
  }

  const rollingMonthlySales: { name: string, [key: string]: number | string }[] = [ 
    { name: "Jan" }, 
    { name: "Feb" }, 
    { name: "Mar" }, 
    { name: "Apr" }, 
    { name: "May" }, 
    { name: "Jun" }, 
    { name: "Jul" }, 
    { name: "Aug" }, 
    { name: "Sep" }, 
    { name: "Oct" }, 
    { name: "Nov" }, 
    { name: "Dec" } 
  ];

  for (const repName in preFormattedMonthlySales) {
    for (const month in rollingMonthlySales) {
      if (preFormattedMonthlySales[repName][rollingMonthlySales[month].name]) {
        rollingMonthlySales[month][repName] = preFormattedMonthlySales[repName][rollingMonthlySales[month].name];
      } else {
        rollingMonthlySales[month][repName] = 0;
      }
    }
  }

  console.log(rollingMonthlySales, preFormattedMonthlySales)

  return { salesRepData, rollingMonthlySales };
}