import { useState } from "react";

type CollapsibleTextProps = {
    text: string, maxLength: number, expandedByDefault: boolean
}

export const CollapsibleText = ({text, maxLength, expandedByDefault = false}: CollapsibleTextProps) => {
    const [expanded, setExpanded] = useState(expandedByDefault);

    function toggleExpanded() {
        const newExpanded: boolean = !expanded

        setExpanded(newExpanded);

    }

    return (
        <span className='flex flex-row items-end'>
            {text.slice(0, (expanded ? undefined : maxLength))}
            {text.length > maxLength && 
                <abbr title={expanded ? "See less" : "See more"}>
                    <i 
                        className="ml-0.5 hover:cursor-pointer fa-solid fa-ellipsis text-gray-500 rounded-lg bg-[#3A5BFF26] pl-1 pr-1" 
                        onClick={() => toggleExpanded()} 
                        data-testid={`collapsible-text-max-${maxLength}`}>
                    </i>
                </abbr>
            }
        </span>
    );
}