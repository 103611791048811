import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchCustomer, updateCustomer, fetchDashboardData, fetchProductCategories } from '../api';
import { ContactData, Customer, Sort } from '../types';

import BackButton from '../components/backLink';

import ModalWithChildren from '../components/modalWithChildren';
import CustomerAndSupplierNotes from '../components/widgets/newNoteModal';
import SimpleMetricCard from '../components/widgets/simpleMetricCard';

import { getDateInD3Format, getSortByKey, setSortOn, sort, unwrapD3DateToISO } from '../utils';
import PageTabs from '../components/pageTabs';
import { contactsData } from '../dummyData';
import ContactTab from '../components/contactTab';
import { SortColumnArrow } from '../components/widgets/sortColumnArrow';
import SearchBar from '../components/searchBar';
import ContactsGrid from '../components/contactsGrid';

const CustomersCard = () => {
  const { id: customerId } = useParams<{ id: string }>();
  const newCustomer = customerId === "new";
  
  const tabOptions = ["Overview", "Financials", "Customer Terms", "Contacts"];
  
  const [tab, setTab] = useState(tabOptions[0]);
  const [originalData, setOriginalData] = useState({} as Customer);
  const [customerData, setCustomerData] = useState({} as Customer);
  const [fetchingData, setFetchingData] = useState(true);
  const [categoryTerms, setCategoryTerms] = useState<{ [key: string]: string }>({});
  const [customerTerms, setCustomerTerms] = useState("" as "" | "Bronze" | "Silver" | "Gold");

  const [contacts, setContacts] = useState<ContactData[]>(contactsData);
  
  


  const [customerStopModalOpen, setCustomerStopModalOpen] = useState(false);

  const { setScreenId, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`CUSTOMER- ${customerId}`);

  useEffect(() => {
    if (!customerId) return;

    const fetchData = async () => {
      try {
        const data = await fetchCustomer(customerId);
        console.log(data);

        if (data) {
          setOriginalData(data);
          setCustomerData(data);
        }

        let categories = await fetchProductCategories();
        if (categories) {
          const initialTerms = categories
            .filter((category) => category !== "")
            .reduce((acc, category) => ({ ...acc, [category]: "" }), {});
        
          setCategoryTerms(initialTerms);
        }

        console.log(categories);
      } catch (err) {
        console.error('There was a problem fetching accounts:', err);
        addNotification("There was a problem fetching the customer. Please try again.", "error");
      }
      setFetchingData(false);

      let orderData = await fetchDashboardData(customerId);
      console.log(orderData);
    };

    fetchData();
  }, [customerId]);

  

  const inputVariable = (label: string, valueKey: keyof Customer) => {
    return <>
      <div className="label">{label}</div>
      <input 
        type="text" 
        className="input outline-none" 
        placeholder={`${label}...`} 
        value={customerData[valueKey]} 
        onChange={(e) => {
          setPageHasDirtyForm(true);
          setCustomerData({ ...customerData, [valueKey]: e.target.value })
        }}
      />
    </>
  }

  const updateValue = (key: keyof Customer, value: string) => {
    setPageHasDirtyForm(true);
    setCustomerData({ ...customerData, [key]: value });
  }

  const updateCustomerData = async () => {
    if (!customerId) return;

    try {
      console.log("Updating customer data...");

      const updateResponse = await updateCustomer(customerId, originalData, customerData);
      console.log(updateResponse);
      
      if (!updateResponse) {
        addNotification("There was a problem updating the customer. Please try again.", "error");
        return;
      }

      setOriginalData(customerData);
      setPageHasDirtyForm(false);
      addNotification("Customer updated successfully.", "success");
    } catch (err: any) {
      console.log(err)
      addNotification(err.message, "error");
      console.error('There was a problem updating customer:', err);
    }
  }

  const attemptSave = async () => {
    console.log("attempting to save");
  }

  return (
    <div className="flex flex-col gap-6 items-start">
      {customerStopModalOpen &&
        <ModalWithChildren
          children={
            <div className="flex flex-col gap-2 items-center">
              <h2 className="font-semibold text-2xl">Customer on Stop</h2>
              <p className="mt-4 max-w-[500px] text-center">Putting this Customer on Stop will mean they can no longer purchase any products at all locations, including the Website.<br /><br />Confirm this is the action you want.</p>
              <button
                className="btn btn-negative w-full mt-4"
                onClick={() => {
                  updateValue("CREDIT.STATUS", "STOP");
                  setCustomerStopModalOpen(false);
                }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary w-full"
                onClick={() => setCustomerStopModalOpen(false)}
              >
                Cancel
              </button>

            </div>}
          modalOpen={customerStopModalOpen}
          setModalOpen={setCustomerStopModalOpen}
        />
      }

      <div className="flex items-center gap-4 w-full">
        <div>
          <BackButton />
          <h2 className="font-semibold text-2xl">
            {newCustomer 
              ? "New Customer"
              : customerData.NAME
            }
          </h2>
        </div>

        {newCustomer 
          ? <>
              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  window.location.href = "/maintenance";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-green"
                onClick={attemptSave}
              >
                Save
              </button>
            </>
          : <button
              className="btn btn-green ml-auto disabled:opacity-50"
              onClick={updateCustomerData}
              disabled={fetchingData || !customerId || (JSON.stringify(originalData) === JSON.stringify(customerData))}
            >
              Save
            </button>
        }
      </div>

      <div className="grid md:grid-cols-2 gap-4 w-full">
        <SimpleMetricCard
          identifier='Total'
          metric='Orders'
          count={0}
          change={null}
          period='year'
          icon='cart-shopping'
          fetching={fetchingData}
          theme='green'
        />

        <SimpleMetricCard
          identifier='Total'
          metric='Spend'
          count={0}
          change={null}
          period='year'
          icon='cash-register'
          fetching={fetchingData}
          theme='green'
        />
      </div>

      <div className="grid md:grid-cols-3 gap-6 w-full">
        <div className="col-span-2">
          <div className="dashboard-card">
            <div className="card-header bg-green">
              {/* <PageTabs tabs={tabOptions}  /> */}
              {tabOptions.map((option, index) => (
                <button 
                  key={index} 
                  className={`px-2 pb-2 btn font-bold
                      ${tab === option 
                        ? 'bg-white text-green-700' 
                        : 'hover:bg-white hover:text-green-700 text-white'}
                  `} 
                  onClick={() => setTab(option)}
                >
                  {option}
                </button>
              ))}
            </div>

            {fetchingData 
              ? <div className="flex flex-col justify-center items-center flex-grow py-12">
                  <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
                </div>
              : <div className="p-4">
                  {tab === "Overview" && <>
                    <h3 className="font-semibold">Primary Details</h3>

                    <div className="form-container mt-4">
                      <div className="label">Account Number</div>
                      <p className="text-sm pl-3">{customerId}</p>

                      {inputVariable("Customer name", "NAME")}
                      
                      <div className="label">Address</div>
                      <textarea 
                        className="input outline-none" 
                        placeholder="Address..." 
                        rows={4}
                        value={typeof customerData.ADDRESS === "string" 
                          ? customerData.ADDRESS 
                          : customerData.ADDRESS !== undefined
                            ? customerData.ADDRESS.join('\n')
                            : undefined
                        }
                        onChange={(e) => {
                          setPageHasDirtyForm(true);
                          setCustomerData({ ...customerData, ADDRESS: e.target.value.split('\n') })
                        }}
                      />

                      {inputVariable("Postcode", "POSTCODE")}
                      {inputVariable("VAT Type", "VAT.TYPE")}
                      {inputVariable("Phone", "PHONE")}
                      {inputVariable("Account Holding Location", "AREA")}
                      {inputVariable("External Sales Rep", "REP")}
                    </div>
                  </>}

                  {tab === "Financials" && <>
                    <h3 className="font-semibold">Financial Details</h3>

                    <div className="form-container mt-4">
                      <div className="label">Credit Limit</div>
                      <div className="flex rounded border border-gray-300 bg-white items-center justify-start text-[12px]">
                        <div className="border-gray-300 border-r px-1 h-full flex items-center">
                          <p>£</p>
                        </div>
                        <div className="px-1 flex-grow">
                          <input 
                            type="number" 
                            className="w-full outline-none py-[3px]" 
                            placeholder={`Customer's credit limit...`}
                            value={customerData["CREDIT.LIMIT"]}
                            onChange={(e) => {
                              setPageHasDirtyForm(true);
                              setCustomerData({ ...customerData, "CREDIT.LIMIT": parseFloat(e.target.value) })
                            }}
                          />
                        </div>
                      </div>

                      <label className="label">Credit Limit Review Date</label>
                      <input 
                        type="date" 
                        className="input outline-none" 
                        value={customerData["REVIEW.DATE"] ? unwrapD3DateToISO(customerData["REVIEW.DATE"]) : ""}
                        onChange={(e) => {
                          setPageHasDirtyForm(true);
                          setCustomerData({ ...customerData, "REVIEW.DATE": getDateInD3Format(new Date(e.target.value)) })
                        }}
                      />

                      {inputVariable("Payment Terms", "TRADING.TERMS")}
                      {inputVariable("Customer on Stop", "CREDIT.STATUS")}

                      <div className="label">Debt Insured</div>
                      <div className="flex rounded border border-gray-300 bg-white items-center justify-start text-[12px]">
                        <div className="border-gray-300 border-r px-1 h-full flex items-center">
                          <p>£</p>
                        </div>
                        <div className="px-1 flex-grow">
                          <input 
                            type="number" 
                            className="w-full outline-none py-[3px]" 
                            placeholder={`Customer's insured debt...`}
                            value={customerData["DEBT.INSURED"]}
                            onChange={(e) => {
                              setPageHasDirtyForm(true);
                              setCustomerData({ ...customerData, "DEBT.INSURED": e.target.value })
                            }}
                          />
                        </div>
                      </div>
  

                      <div className="label">Customer on Stop</div>
                      <button 
                        className={`btn btn-${customerData["CREDIT.STATUS"] === "STOP" ? "green" : "negative"} w-full`}
                        onClick={() => {
                          if (customerData["CREDIT.STATUS"] === "STOP") {
                            updateValue("CREDIT.STATUS", "OK");
                          }
                          else {
                            setCustomerStopModalOpen(true);
                          }
                        }}
                      >
                        {customerData["CREDIT.STATUS"] === "STOP" ? "Remove Stop" : "Put on Stop"}
                      </button>
                    </div>
                  </>}

                  {tab === "Contacts" && <ContactsGrid contacts={contacts} setContacts={setContacts} theme="green"/>}

                  {tab === "Customer Terms" && <>
                    <h3 className="font-semibold">Customer Terms</h3>
                    <div className="form-container mt-4">
                      <label className="label">Main Terms Group</label>
                      <select
                        className="input outline-none border border-gray-300 w-full rounded-md p-1"
                        value={customerTerms}
                        onChange={(e) => {
                          setPageHasDirtyForm(true);
                          setCustomerTerms(e.target.value as "" | "Bronze" | "Silver" | "Gold");
                        }}
                      >
                        <option disabled hidden value="">
                          No terms set...
                        </option>
                        <option value="Bronze">Bronze</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                      </select>
                    </div>

                    <h3 className="font-semibold mt-8">Category Terms</h3>

                    <div className="grid w-full md:grid-cols-2 gap-6 mt-4">
                    {Object.keys(categoryTerms).map((category, index) => (
                      <div key={index}>
                        <p>{category}</p>
                        <select
                          className="input outline-none border border-gray-300 w-full rounded-md p-1"
                          value={categoryTerms[category]}
                          onChange={(e) => {
                            setPageHasDirtyForm(true);
                            setCategoryTerms((prevTerms) => ({
                              ...prevTerms,
                              [category]: e.target.value,
                            }));
                          }}
                        >
                          <option disabled hidden value="">
                            No terms set...
                          </option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>
                    ))}
                    </div>
                  </>}
                </div>
            }
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-sticky-note"></i>
              <h3 className="font-semibold uppercase text-sm">Customer Notes</h3>
            </div>

            <CustomerAndSupplierNotes
              note={customerData['CC.COMMENTS']}
              setNote={(note: string[]) => setCustomerData({ ...customerData, "CC.COMMENTS": note }) }
              theme='green'
            />
          </div>

          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-history"></i>
              <h3 className="font-semibold uppercase text-sm">Activity History</h3>
            </div>
            
            <div className="p-4">
              <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created customer record.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersCard;