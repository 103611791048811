import { useEffect } from 'react';
import { fetchProducts } from './api';
import { Product, Sort, User } from './types';

export const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type KeyPressAction = () => void;

export const useKeyPress = (targetKey: string, action: KeyPressAction): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      console.log(event.key);
      if (event.key === targetKey) {
        event.preventDefault(); // Prevent default browser behavior if needed
        action(); // Execute the provided callback function
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [targetKey, action]);
};

export const fetchProductData = async () => {
  const data: Product[] = await fetchProducts();

  const products = data.filter((product: Product) => !product["PRODUCT.CODE"].includes("(") && !product["PRODUCT.CODE"].includes(")"));

  const productCategories: string[] = [];

  products.forEach((product: Product) => {
    if (!product["PRODUCT.CODE"] || product["PRODUCT.CODE"] === "") {
      console.log("Product code missing for product:", product);
    }

    if (!productCategories.includes(product["PGROUP.DESC"])) productCategories.push(product["PGROUP.DESC"]);
    if ((product["BOURNEMOUTH"] && product["BOURNEMOUTH"] !== "") &&  product["SHEFFIELD"] && product["SHEFFIELD"] !== "" && product["HULL"] && product["HULL"] !== "") {
      product["Total Stock"] = parseInt(product["BOURNEMOUTH"]) + parseInt(product["SHEFFIELD"]) + parseInt(product["HULL"]);
    }
    else product["Total Stock"] = 0;
  });

  return { products, productCategories }
}

export const sortAlphabeticallyWithNonAlphaAtEnd = (object: any[], sortKey: string) => {
  return object.sort((a: any, b: any) => {
    const regex = /^[a-zA-Z]/;

    const aValue = a[sortKey] as unknown as string;
    const bValue = b[sortKey] as unknown as string;

    const aStartsWithLetter = regex.test(aValue);
    const bStartsWithLetter = regex.test(bValue);

    if (aStartsWithLetter && !bStartsWithLetter) {
      return -1; // a comes before b
    } else if (!aStartsWithLetter && bStartsWithLetter) {
      return 1; // b comes before a
    } else {
      return aValue.localeCompare(bValue);
    }
  });
};

export const formatNumber = (number: number) => {
  if (!number) return 0;
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  }
  if (number > 1000) {
    return `${(number / 1000).toFixed(0)}K`;
  }
  return number.toLocaleString();
}

export const getDateInD3Format = (date: Date) => {
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
  const year = String(date.getUTCFullYear());
  
  console.log("Output date:", `${day} ${month} ${year}`);
  return `${day} ${month} ${year}`;
}

export const unwrapD3DateToISO = (d3Date: string) => {
  const date = new Date(`${d3Date} UTC`);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const convertDateObjToInputTagReadable = (date: Date, includeTime: boolean = false) => {
  //Year month day part from Claude AI
  // Get year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Combine the values into a YYYY-MM-DD string
  let dateTime = `${year}-${month}-${day}`;
  if (!includeTime) return dateTime;

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padEnd(3, "0");

  return `${dateTime} ${hours}:${minutes}:${seconds}.${milliseconds}`
}


export const getDateFromD3DateAndTime = (dateString: string, timeString: string) => {
  // Convert "02 Aug 24" to a Date object
  const [day, month, year] = dateString.split(' ');

  // Add the time to the date (in format "HH:MM:SS")
  const [hours, minutes, seconds] = timeString.split(':');
  const date = new Date(`${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`);
  return date;
};

export const getTimeInD3Format = (date: Date) => {
  return date.toTimeString().split(' ')[0];
}

export function getSortByKey<T>(sorts: Sort<T>[], key: keyof T): Sort<T> | undefined {
  return sorts.filter((value) => value.key === key)[0];
}

export function setSortOn<T>(sorts: Sort<T>[], key: keyof T, asc?: boolean, subKey?: string): Sort<T>[] {
  //Cycle from true, false, undefined if undefined. Default to true if no existing sort exists (coalesce with false so it is inverted into true).
  let finalAsc: boolean | undefined = true;
  const existingValue: Sort<T> | undefined =  getSortByKey(sorts, key);
  const existingAsc: boolean | undefined = existingValue?.asc;
  if (asc !== undefined) finalAsc = asc;
  else if (existingAsc === false) return unSortOn(sorts, key);
  else finalAsc = !existingValue;

  //Create new arrays because React
  if (sorts.map((value) => value.key).includes(key)) return [...sorts.filter((value) => value.key !== key), {key: key, asc: finalAsc, subKey: existingValue?.subKey}];
  else sorts.push({key: key, asc: finalAsc, subKey: subKey});

  return [...sorts];
}

export function unSortOn<T>(sorts: Sort<T>[], key: keyof T): Sort<T>[] {
  return [...sorts.filter((value) => value.key !== key)];
}

function traverseObj<T>(obj: T, keyPath: string): any {
  const splitPath: string[] = keyPath.split('.');
  let currObj: any = obj;

  for (let i = 0; i < splitPath.length; i++) {
    currObj = currObj[splitPath[i]];
  }

  return currObj;
}

export function sort<T>(objSorting: T[], sorts: Sort<T>[]): T[] {
  if (sorts.length < 1) return objSorting;
  
  //Get copy of objSorting as the result
  let result: T[] = Array.from(objSorting)

  result.sort((a: T, b: T): number => {
    let sortNum = 0;
    for (const sortObj of sorts) {
      //If there is a subkey defined, traverse the object by that subkey to get the correct value.
      const aValue: any = sortObj.subKey ? traverseObj<T>(a, (sortObj.key as string) + '.' + sortObj.subKey) : a[sortObj.key];
      const bValue: any = sortObj.subKey ? traverseObj<T>(b, (sortObj.key as string) + '.' + sortObj.subKey) : b[sortObj.key];

      if (typeof aValue === "number") sortNum = (bValue as number) - (aValue as number);
      else if (aValue instanceof Date) sortNum = (bValue as Date).valueOf() - (aValue as Date).valueOf()
      else sortNum = (bValue as string).localeCompare((aValue as string));

      if (sortObj.asc) sortNum = -sortNum;
      //If a sort is equal, continue loop onto the next sort object to further narrow down the sort
      if (sortNum !== 0) break;
      //Otherwise, break and return the correct sort number (negative if left should be before right, vice versa)
      //If last sort is equal, then return the sort number for equal (0)
      //TODO: Don't forget to check the typeof their object. Treat null/falseys as always first/last depending on ascending or descending - a really big negative order number
    }

    return sortNum;
  })

  return result;
}