import React, {useState, useEffect, useRef} from 'react';

type ModalWithChildrenProps = {
  children: React.ReactNode;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalWithChildren = ({ children, modalOpen, setModalOpen }: ModalWithChildrenProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    // Add type check for event.target as well
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div 
      className={`h-screen w-screen top-0 left-0 bg-black bg-opacity-65 flex items-center justify-center ${modalOpen ? "fixed" : "hidden"}`}
      style={{zIndex: 9999}}
    >
      <div ref={modalRef} className="dashboard-card bg-base-100 p-4 md:p-6 md:min-w-[600px] lg:max-w-[1250px]">
        {children}
      </div>
    </div>
  );
}

export default ModalWithChildren;