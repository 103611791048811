import { useEffect, useRef, useState } from "react";
import { ContactData } from "../types";
import { convertDateObjToInputTagReadable } from "../utils";
import BackLink from "./backLink";

type ContactTabProps = {
    contactData: ContactData,
    closeTab: () => void,
    deleteContact: () => void,
    theme?: string
}

const ContactTab = ({contactData, closeTab, deleteContact, theme = "blue"}: ContactTabProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const address = useRef('');
    const [telephone, setTelephone] = useState('');
    const [dateAdded, setDateAdded] = useState<Date>(new Date());
    const [lastAmended, setLastAmended] = useState<Date>(new Date());

    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [postcode, setPostcode] = useState('');

    useEffect(() => {
        setName(contactData.name);
        setEmail(contactData.email);
        address.current = contactData.address;
        const splitAddress: string[] = address.current.split(', ');
        let addressSegments: number = splitAddress.length;

        if (addressSegments <= 4) { 
            setPostcode(splitAddress[addressSegments - 1]);

            addressSegments -= 1;
            
            const sets = [setAddress3, setAddress2, setAddress1];
            for (let i = 0; addressSegments > 0; addressSegments -= 1) {
                sets[addressSegments - 1](splitAddress[i])
                i++;
            }
        }

        setTelephone(contactData.telephone);
        setDateAdded(contactData.dateAdded);
        setLastAmended(contactData.lastAmended);
    }, [contactData])

    function save() {
      contactData.name = name;
      contactData.email = email;
      contactData.address = [address1, address2, address3, postcode].join(', ');
      contactData.telephone = telephone;
      contactData.dateAdded = dateAdded;
      contactData.lastAmended = lastAmended;

      closeTab();
    }

    return (
        <section className='flex flex-col gap-2'>
            <div className="flex flex-col form-container mt-4">
                <label htmlFor="contactsTabNameInput" className="label">Name</label>
                <input className="input w-full outline-none py-[3px]" id="contactsTabNameInput" value={name} onChange={(e: React.ChangeEvent) => setName((e.target as HTMLInputElement).value)}/>

                <label htmlFor="contactsTabEmailInput" className="label">Email</label>
                <input type="email" className="input w-full outline-none py-[3px]" id="contactsTabEmailInput" value={email} onChange={(e: React.ChangeEvent) => setEmail((e.target as HTMLInputElement).value)}/>

                <label htmlFor="contactsTabAddressInput" className="label">Address</label>
                {/* Because form-container zigzags, these two empty divs make it so that this appears on the row below without requiring any actual visible content */}
                <div></div>
                <div></div>
                <div id="contactsTabAddressInput" className="flex flex-col border-solid border-gray-300 border-2 p-2 gap-2">
                    <div className='flex flex-row'>
                        <label className="w-28 label">Address 1</label>
                        <input className="input w-full outline-none py-[3px]" value={address1}  onChange={(e: React.ChangeEvent) => setAddress1((e.target as HTMLInputElement).value)}/>
                    </div>
                    <div className='flex flex-row'>
                        <label className="w-28 label">Address 2</label>
                        <input className="input w-full outline-none py-[3px]"  value={address2} onChange={(e: React.ChangeEvent) => setAddress2((e.target as HTMLInputElement).value)}/>
                    </div>
                    <div className='flex flex-row'>
                        <label className="w-28 label">Address 3</label>
                        <input className="input w-full outline-none py-[3px]"  value={address3} onChange={(e: React.ChangeEvent) => setAddress3((e.target as HTMLInputElement).value)}/>
                    </div>
                    <div className='flex flex-row'>
                        <label className="w-28 label">Postcode</label>
                        <input className="input w-full outline-none py-[3px]"  value={postcode} onChange={(e: React.ChangeEvent) => setPostcode((e.target as HTMLInputElement).value)}/>
                    </div>
                </div>

                <label htmlFor="contactsTabTelephoneInput" className="label">Telephone</label>
                <input 
                    className="input w-full outline-none py-[3px]" 
                    id="contactsTabTelephoneInput" 
                    type="tel" 
                    value={telephone} 
                    onChange={(e: React.ChangeEvent) => setTelephone((e.target as HTMLInputElement).value)}
                />

                <label htmlFor="contactsTabDateAddedInput" className="label">Date Added</label>
                <input 
                    type="date"
                    className="input w-full outline-none py-[3px]" 
                    id="contactsTabDateAddedInput" 
                    value={convertDateObjToInputTagReadable(dateAdded)} 
                    onChange={(e: React.ChangeEvent) => setDateAdded(new Date((e.target as HTMLInputElement).value))}
                />

                <label htmlFor="contactsTabLastAmendedInput" className="label">Last Amended</label>
                <input 
                    type="datetime-local"
                    className="input w-full outline-none py-[3px]" 
                    id="contactsTabLastAmendedInput" 
                    value={convertDateObjToInputTagReadable(lastAmended, true)} 
                    onChange={(e: React.ChangeEvent) => setLastAmended(new Date((e.target as HTMLInputElement).value))}
                />
                
            </div>
            <div className="flex flex-row gap-2 w-full justify-between">
                <div className="flex flex-row gap-2 items-start">
                    <button className={`btn btn-primary ${theme === 'green' ? 'btn-green' : ''}`} onClick={save}>Save</button>
                    <button className={`btn btn-secondary${theme === 'green' ? '-green' : ''}`} onClick={closeTab}>Cancel</button>
                </div>
                <div className="flex flex-row items-end">
                    <button className="btn bg-red-400 text-white" onClick={() => {
                        if (window.confirm("Are you sure you want to delete this contact?")) {
                            closeTab(); 
                            deleteContact();
                        }
                    }}>Delete</button>
                </div>
            </div>
        </section>
    )
  }

  export default ContactTab;