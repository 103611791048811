import React, { useEffect, useState } from 'react';

import { processDataIntoDailyTotals } from '../../pages/dashboard/utils';
import { Order } from '../../types';

import SimpleBarChart from './charts/barChart';
import MetricChangeHandler from './metricChangeHandler';

const DailyTotals = ({ ordersThisWeek, ordersLastWeek, fetching }: { ordersThisWeek: Order[], ordersLastWeek: Order[], fetching: boolean }) => {
  const [rollingDailyOrders, setRollingDailyOrders] = useState<{ name: string; "Last Week": number; "This Week": number; }[]>([]);
  const [widgetLoading, setWidgetLoading] = useState(true);
  const [metric, setMetric] = useState("Sales");
  const [change, setChange] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (fetching) return;
    setWidgetLoading(true);
    const dailyFigures = processDataIntoDailyTotals(ordersThisWeek, ordersLastWeek, metric);
    setRollingDailyOrders(dailyFigures);

    let daysWithNonZeroValues = dailyFigures.filter(day => day["This Week"] > 0);
    console.log(daysWithNonZeroValues);
    if (daysWithNonZeroValues.length > 1) {
      setChange(daysWithNonZeroValues[daysWithNonZeroValues.length - 1]["This Week"] - daysWithNonZeroValues[daysWithNonZeroValues.length - 2]["This Week"]);
    }
    else if (daysWithNonZeroValues.length === 1) {
      setChange(daysWithNonZeroValues[daysWithNonZeroValues.length - 1]["This Week"] - daysWithNonZeroValues[daysWithNonZeroValues.length - 1]["Last Week"]);
    }
    setWidgetLoading(false);
  }, [ordersThisWeek, ordersLastWeek, metric, fetching]);

  const handleMetricChange = (newMetric: string) => {
    setMetric(newMetric);
    setShowDropdown(false);
  };

  return (
    <div className="dashboard-card">
      <div className="card-header bg-green">
        <i className="fas fa-chart-line text-lg"></i>
        <h2>HDM Daily {metric}</h2>

        <div className="relative ml-auto">
          <i 
            className="cursor-pointer fa-regular fa-ellipsis-vertical" 
            onClick={() => setShowDropdown(!showDropdown)}
          ></i>
          {showDropdown && (
            <div className="text-black absolute right-0 w-32 bg-white border border-gray-200 rounded shadow-md z-50">
              <ul className="">
                <li 
                  className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Sales' ? 'bg-blue-100' : ''}`}
                  onClick={() => handleMetricChange('Sales')}
                >
                  Sales
                </li>
                <li 
                  className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Orders' ? 'bg-blue-100' : ''}`}
                  onClick={() => handleMetricChange('Orders')}
                >
                  Orders
                </li>
                <li 
                  className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Profit' ? 'bg-blue-100' : ''}`}
                  onClick={() => handleMetricChange('Profit')}
                >
                  Profit
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {widgetLoading 
        ? <div className="flex flex-col gap-4 items-center justify-center w-full h-48 rounded-md p-4">
            <i className="fas fa-spinner fa-spin text-2xl md:text-4xl"></i>
            <h3 className="font-bold text-center">Loading daily data...</h3>
          </div>
        : <div className="pr-4 py-2">
            <p className="ml-4 mb-2 text-sm text-muted">
              <MetricChangeHandler metric={metric} metricChange={change} giveTextColor={true} />
              &nbsp;{change < 0 ? `behind yesterday's` : `ahead of yesterday's`} {metric}.
            </p>

            <SimpleBarChart chartData={rollingDailyOrders} includeAverageLine={false} metric={metric} />
          </div>
      }
    </div>
  )
}

export default DailyTotals;